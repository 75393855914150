import { dateFormat } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr, amtStr4Dec } from 'helpers/StringNumberFunction/NumFormatters'
import { renderToStaticMarkup } from 'react-dom/server'
import QRCode from 'react-qr-code'
import React from 'react'

const generateQRCodeSVG = value => {
  const qrCodeElement = (
    <QRCode
      size={128}
      style={{ height: '75px', width: '75px' }}
      value={value}
      // viewBox={`0 0 128 128`}
    />
  )
  return renderToStaticMarkup(qrCodeElement)
}

export const ELetterServiceAR = ({
  docType,
  previewDetail,
  allpreviewDetail,
  paramVal,
  ItemIndex,
}) => {
  const debtorDetails = {
    '[DebtorName]':
      previewDetail?.DebtorAccount?.CompanyName! ??
      previewDetail?.DebtorAccount?.DebtorName!,
    '[DebtorAddress]': `${previewDetail?.DebtorAccount?.Address?.address}<br />
          ${
            previewDetail?.DebtorAccount?.Address?.country == 'Singapore'
              ? `${previewDetail?.DebtorAccount?.Address?.country} ${previewDetail?.DebtorAccount?.Address?.postCode}`
              : `${previewDetail?.DebtorAccount?.Address?.postCode} ${previewDetail?.DebtorAccount?.Address?.city}, 
            ${previewDetail?.DebtorAccount?.Address?.state}, ${previewDetail?.DebtorAccount?.Address?.country}`
          }
           <br />`,
    '[DebtorContactName]':
      previewDetail?.DebtorAccount?.DebtorContact?.Name! ?? '',
    '[DebtorContactNo]':
      previewDetail?.DebtorAccount?.DebtorContact?.ContactNo! ?? '',
    '[DebtorContactEmail]':
      previewDetail?.DebtorAccount?.DebtorContact?.Email! ?? '',
    '[DebtorCompanyRegNo]': previewDetail?.DebtorAccount?.CompanyRegNo! ?? '',
    '[DebtorBankAccName]': previewDetail?.DebtorAccount?.BankName! ?? '',
    '[DebtorBankAccNo]': previewDetail?.DebtorAccount?.BankAccountNo! ?? '',
    '[DebtorTIN]': previewDetail?.DebtorAccount?.einvoice_TIN! ?? '',
    '[TaxSSTRegNo]': previewDetail?.SSTRegNo! ?? '',
    '[TaxGSTRegNo]': previewDetail?.GSTRegNo! ?? '',
    '[DocumentNo]': previewDetail?.DocNo! ?? '',
    '[DocumentDate]': dateFormat(previewDetail?.DocDate!) ?? '',
    '[ReferenceNo]': previewDetail?.RefNo! ?? '',

    '[QRCode]':
      previewDetail?.is_einvoice && previewDetail?.einvoice_qr_url
        ? generateQRCodeSVG(previewDetail?.einvoice_qr_url)
        : '',
  }

  switch (docType) {
    case 'official-receipt':
      Object.assign(paramVal, debtorDetails, {
        '[Remark]': previewDetail?.Remark! ?? '',
        '[Description]': previewDetail?.Description! ?? '',
        '[BankCode]': previewDetail?.BankAccount?.Code! ?? '',
        '[BankAccNo]': previewDetail?.BankAccount?.AccountNo! ?? '',
        '[PaymentMethod]': previewDetail?.PaymentMethod?.Name! ?? '',
        '[ChequeNo]': previewDetail?.ChequeNo! ?? '',
        '[TotalAmt]': amtStr(previewDetail?.DocAmt) ?? '',
        '[SumTotalAmt]': amtStr(previewDetail?.DocAmt) ?? '',
      })
      break
    case 'refund':
      Object.assign(paramVal, debtorDetails, {
        '[Remark]': previewDetail?.Remark! ?? '',
        '[Description]': previewDetail?.Description! ?? '',
        '[BankCode]': previewDetail?.BankAccount?.Code! ?? '',
        '[BankAccNo]': previewDetail?.BankAccount?.AccountNo! ?? '',
        '[TotalAmt]': amtStr(previewDetail?.DocAmt) ?? '',
        '[SumTotalAmt]': amtStr(previewDetail?.DocAmt) ?? '',
      })
      break
    case 'invoice':
      return Object.assign(paramVal, debtorDetails, {
        '[DueDate]': dateFormat(previewDetail?.DueDate!) ?? '',
        '[TaxGSTRegNo]': previewDetail?.DebtorAccount?.GSTRegNo! ?? '',
        '[Description]': previewDetail?.Description! ?? '',

        items: previewDetail?.ARInvoiceItem?.map((el, i) => {
          return {
            '[Index]': ItemIndex + i + 1,
            '[Remark]': el?.Remark,
            '[TaxRate]': amtStr(el?.TaxRate),
            '[BaseAmt]': amtStr(el?.Amount),
            '[TaxAmt]': amtStr(el?.TaxAmt),
            '[TotalAmt]': amtStr(el?.DocAmt),
          }
        }),
        '[SumBaseAmt]': amtStr(
          allpreviewDetail?.ARInvoiceItem?.reduce(
            (a, b) => a + Number(b?.Amount),
            0
          )
        ),
        '[SumTaxAmt]': amtStr(
          allpreviewDetail?.ARInvoiceItem?.reduce(
            (a, b) => a + Number(b?.TaxAmt),
            0
          )
        ),
        '[SumTotalAmt]': amtStr(
          allpreviewDetail?.ARInvoiceItem?.reduce(
            (a, b) => a + Number(b?.DocAmt),
            0
          )
        ),
      })

    case 'credit-note':
      Object.assign(paramVal, debtorDetails, {
        '[Description]': previewDetail?.Description! ?? '',

        items: previewDetail?.ARCreditNoteItem?.map((el, i) => {
          return {
            '[Index]': ItemIndex + i + 1,
            '[Remark]': el?.Remark,
            '[TaxRate]': amtStr(el?.TaxRate) ?? '',
            '[TaxAmt]': amtStr(el?.TaxAmt) ?? '',
            '[BaseAmt]': amtStr(el?.Amount) ?? '',
            '[TotalAmt]': amtStr(el?.DocAmt),
          }
        }),
        '[SumBaseAmt]':
          amtStr(
            allpreviewDetail?.ARCreditNoteItem?.reduce(
              (a, b) => a + Number(b?.Amount),
              0
            ).toFixed(2)
          ) ?? '',
        '[SumTaxAmt]':
          amtStr(
            allpreviewDetail?.ARCreditNoteItem?.reduce(
              (a, b) => a + Number(b?.TaxAmt),
              0
            ).toFixed(2)
          ) ?? '',
        '[SumTotalAmt]': amtStr(
          allpreviewDetail?.ARCreditNoteItem?.reduce(
            (a, b) => a + Number(b?.DocAmt),
            0
          )
        ),
      })
      break
    case 'debit-note':
      Object.assign(paramVal, debtorDetails, {
        '[Description]': previewDetail?.Description! ?? '',
        '[DueDate]': dateFormat(previewDetail?.DueDate!) ?? '',

        items: previewDetail?.ARDebitNoteItem?.map((el, i) => {
          return {
            '[Index]': ItemIndex + i + 1,
            '[Remark]': el?.Remark,
            '[TaxRate]': amtStr(el?.TaxRate) ?? '',
            '[TaxAmt]': amtStr(el?.TaxAmt) ?? '',
            '[BaseAmt]': amtStr(el?.Amount) ?? '',
            '[TotalAmt]': amtStr(el?.DocAmt),
          }
        }),
        '[SumBaseAmt]':
          amtStr(
            allpreviewDetail?.ARDebitNoteItem?.reduce(
              (a, b) => a + Number(b?.Amount),
              0
            ).toFixed(2)
          ) ?? '',
        '[SumTaxAmt]':
          amtStr(
            allpreviewDetail?.ARDebitNoteItem?.reduce(
              (a, b) => a + Number(b?.TaxAmt),
              0
            ).toFixed(2)
          ) ?? '',
        '[SumTotalAmt]': amtStr(previewDetail?.DocAmt),
      })
      break
  }
}
