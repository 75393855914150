import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Checkbox,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import { FormAllocationTableContent } from 'components/Table/FormAllocationTableContent'
import { useAPCreateUpdateMutation } from 'containers/APModule/Hooks/useAPCreateUpdateMutation'
import { ItemDialog } from 'containers/APModule/components/ItemDialog'
import { ItemDetail } from 'containers/ARAPModule/Components/Detail/ItemDetail'
import { statusInput } from 'containers/ARAPModule/Function/DocumentChecker'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  DocDateValidationAfter,
  DocDateValidationBefore,
} from 'containers/CashBookModule/DocDateValidation'
import {
  AcctPermission,
  RecordStatus,
  useDocumentListingLazyQuery,
  useGetApAllocationDocumentLazyQuery,
  useGetCompanyNameQuery,
  useGetCostCentreLazyQuery,
  useGetCreditorAccountCompanyAssignmentNameQuery,
  useGetDocNumTitleQuery,
  useGetDocumentDateValidationQuery,
  useGetExpenseItemLazyQuery,
  useGetGeneralGrtnLazyQuery,
  useGetGrtnItemLazyQuery,
  useGetMasterCoaCheckingQuery,
  useGetTaxEffectiveDateQuery,
  useLatestOpenPeriodCheckingDateQuery,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
interface CreditNoteProps {
  DocDate: string
  einvoice_self_billed_date: string
  TrxDate: string
  DocAmt: number
  Remark: string
  Description: string
  approval: string
  user: string
  CreditorAccountID: string
  CreditDebitID: string
  GRTNHeaderID: string
  ExpenseItemID: string
  RefNo: string
  CompanyID: string
  MSICCodeID: string
  isSelfBilled: boolean
  isRefundNote: boolean
  TaxRate: number
  TaxSchemeID: string
  DocNo: string
  UIN: string
  Allocation: any[]
  DocRefID: any[]
}

export const CreditNoteFormWithAllocation = (props: any) => {
  const { formMode, docType } = props
  const { CompanyID, ID }: any = useParams()
  const [taxEffectiveList, setTaxEffectiveList] = useState<any>()
  const [detailMode, setDetailMode] = useState('')

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [selectedTax, setSelectedTax] = useState(null)

  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any

  const [openItemDialog, setOpenItemDialog] = useState<boolean>(false)
  const [openExitConf, setOpenExitConf] = useState(null)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [initDocs, setInitDocs] = useState([])
  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])

  const [creditNoteItemData, setCreditNoteItemData] = useState([])
  const [edit, setEdit] = useState(false)
  const [record, setRecord] = useState(false)
  const [voiceRemark, setVoiceRemark] = useState('')
  //For Default Department
  const [defaultCostCentre, loadDefaultCostCentre] = useState(null)

  const [isSubmit, setIsSubmit] = useState(false)

  const [selectedDocs, setSelectedDocs] = useState<Set<any>>(new Set([]))
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */
  // checking the document number setup

  let refTable, routeSegments, itemRefTable, itemRefTableKey
  switch (docType) {
    case 'credit-note':
      routeSegments = 'Credit Note'
      refTable = 'AP_CreditNote'
      itemRefTable = 'APCreditNoteItem'
      itemRefTableKey = 'CreditNoteItemID'
      break
    case 'creditor-credit-note':
      routeSegments = 'Credit Note from Creditor'
      refTable = 'AP_CreditorCreditNote'
      itemRefTable = 'APCreditorCreditNoteItem'
      itemRefTableKey = 'CreditorCreditNoteItemID'
      break

    default:
      break
  }

  const [docNoChecker, setDocNoChecker] = useState(false)

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RefTable: refTable },
    onCompleted: ({ getDocumentNumberHeader }) => {
      if (
        (getDocumentNumberHeader?.length > 0 &&
          getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true)) ||
        getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true) ||
        (getDocumentNumberHeader?.length === 0 && docType === 'credit-note')
      ) {
        setDocNoChecker(true)
      }
    },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  //e-invoice setup for transaction
  const [isSelfBilled, setIsSelfBilled] = useState(
    editData ? editData?.einvoice_self_billed : false
  )

  const [isRefundNote, setIsRefundNote] = useState(
    editData ? editData?.einvoice_is_refund_note : false
  )

  const CreditNoteFormSchema = yup.object().shape({
    CreditorAccountID: CommonYupValidation.requireField(
      'Creditor Name is required'
    ),
    DocDate: yup.string().required('Doc Date is Required'),
    TrxDate: yup.string().required('Transaction Date is Required'),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    ...(docNoChecker === false && docType === 'creditor-credit-note'
      ? {
          DocNo: yup
            .string()
            .required('Document No. is Required')
            .matches(
              /^(?!\s*$).+/,
              'Entry invalid as field contains space only'
            ),
        }
      : null),

    RefNo: yup.string().required('Reference No is Required'),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
    unregister,
  } = useForm<CreditNoteProps>({
    defaultValues: {
      RefNo: editData ? editData?.RefNo : '',
      DocDate: editData ? editData?.DocDate : new Date(),
      Remark: editData ? editData?.Remark : '',
      Description: editData ? editData?.Description : '',
      CreditorAccountID: editData ? editData?.CreditorAccountID : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(CreditNoteFormSchema),
  })

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()

  // Upload Document
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */
  let docDate = watch('DocDate') ? new Date(watch('DocDate')) : null

  // Company Name
  const {
    loading: CompanyLoading,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RecordStatus: RecordStatus.Active,
    },
    onCompleted: ({ getCompany }) => {
      loadDefaultCostCentre(getCompany[0]?.DefaultCostCentre)
    },
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaCheckingQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
  })
  const {
    loading: DocDateValidationLoading,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {},
  })

  // Creditor Account Company Assignment//

  const {
    loading: CreditorAccountCompanyAssignmentLoading,
    data: { getCreditorAccountCompanyAssignment } = {
      getCreditorAccountCompanyAssignment: [],
    },
  } = useGetCreditorAccountCompanyAssignmentNameQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  let creditorAcc = getCreditorAccountCompanyAssignment?.map(x => {
    return x?.CreditorAccount
  })

  let selectedCreditor = creditorAcc?.filter(
    x => x?.CreditorAccountID === watch('CreditorAccountID')
  )

  //   For allocation purposes
  const [allocatedObj, setAllocatedObj] = useState({})
  const [
    loadAllocationDocument,
    {
      loading: getAllocationDocumentLoading,
      called: getAllocationDocumentCalled,
      data: { getAllocationDocument } = { getAllocationDocument: [] },
    },
  ] = useGetApAllocationDocumentLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: ({ getAllocationDocument }) => {
      let arr = []
      if (getAllocationDocument?.length > 0) {
        getAllocationDocument?.map((alloc, index) => {
          arr.push({
            AllocationID: alloc?.AllocationID,
            CreditOrDebit: alloc?.CreditOrDebit,
            DocDate: alloc?.DocDate,
            DocNo: alloc?.DocNo,
            Description: alloc?.Description,
            // for previous allocated amt on the same doc
            AllocationAmt: Number(
              allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0
            ),
            // balance amount + previous allocated amt on the same doc
            BalanceAmt:
              parseFloat(alloc?.BalanceAmt) +
              Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0),
            // credit or debit id
            [`${alloc?.CreditOrDebit}ID`]: alloc[`${alloc?.CreditOrDebit}ID`],
            // credit or debit ref table
            [`${alloc?.CreditOrDebit}RefTable`]: alloc[
              `${alloc?.CreditOrDebit}RefTable`
            ],
            // credit or debit doc no
            [`${alloc?.CreditOrDebit}DocNo`]: alloc[
              `${alloc?.CreditOrDebit}DocNo`
            ],
          })

          return alloc
        })
      }
      append(arr)
    },
  })

  // General GRTN
  const [
    fetchGeneralGRTN,
    {
      data: { getGeneralGRTN } = { getGeneralGRTN: [] },
      loading: GeneralGRTNLoading,
    },
  ] = useGetGeneralGrtnLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
  })

  // Item GRTN
  const [
    fetchGRTNItem,
    { data: { getGRTNItem } = { getGRTNItem: [] }, loading: ItemGRTNsLoading },
  ] = useGetGrtnItemLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getGRTNItem }) => {
      let arry = []
      if (getGRTNItem?.length > 0) {
        getGRTNItem?.map((el, index) => {
          arry.push({
            GRTNItemID: el?.GRTNItemID,
            ExpenseItemName: el?.ItemName,
            ExpenseItemID: el?.ItemID,
            CostCentreCode: el?.CostCentreCode,
            CostCentreID: el?.CostCentreID,
            Amount: el?.Amount,
            TaxSchemeID: el?.TaxSchemeID,
            TaxCode: el?.TaxCode,
            TaxAmt: el?.TaxAmt,
            TaxRate: el?.TaxRate,
            Remark: el?.Remark ?? el?.Remarks ?? '-',
            Quantity: Number(parseFloat(el?.Quantity).toFixed(4)),
            UnitPrice: Number(parseFloat(el?.UnitPrice).toFixed(4)),
            UOMID: el?.UOMID,
            DocAmt: el?.Amount + el?.TaxAmt,
            Description: '-',
            Sequence: index + 1,
          })
          return el
        })
        setCreditNoteItemData(arry)
      }
    },
  })

  //CostCentre
  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      orderByAsc: 'CostCentreID',
      IsLastNode: true,
    },
  })

  // Item
  const [
    fetchExpenseItem,
    {
      loading: ExpenseItemLoading,
      error: ExpenseItemError,
      data: { getItemByCompany } = { getItemByCompany: [] },
    },
  ] = useGetExpenseItemLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: TaxEffectiveDateLoading,
    error: TaxEffectiveDateError,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (getTaxEffectiveDate?.length > 0) {
        let temp = getTaxEffectiveDate?.filter(
          tax =>
            Number(new Date(tax?.Date)) <
              DocDateValidationAfter(
                getDocumentDateValidation?.map(x => x?.MonthsAfter)
              ) &&
            Number(new Date(tax?.Date)) >
              DocDateValidationBefore(
                getDocumentDateValidation?.map(x => x?.MonthsBefore)
              )
        )
        setTaxEffectiveList(temp)
      }
    },
  })

  const [
    fetchDocuments,
    { loading: documentLoading, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      } else {
        setFiles(editData?.files)
        setPreviewFiles(
          editData?.files?.map(file => {
            return URL.createObjectURL(file)
          })
        )
      }
    },
  })

  // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
  // call fetchDocuments to get existing attachment from db
  useEffect(() => {
    if (
      formMode === 'edit' ||
      formMode === 'approve-reject' ||
      formMode === 'resubmit'
    )
      if (!!!editData?.files) {
        fetchDocuments({
          variables: {
            refID: ID,
          },
        })
      }
  }, [formMode])

  useEffect(() => {
    if (editData?.Remark) {
      setValue('Remark', editData?.Remark)
    }
    if (editData?.Allocation) {
      let allocatedArr = editData?.Allocation
      allocatedArr?.map(allocated => {
        if (!(allocated[`${allocated?.CreditOrDebit}ID`] in allocatedObj)) {
          allocatedObj[allocated[`${allocated?.CreditOrDebit}ID`]] =
            allocated?.AllocationAmt
        }
      })
    }
  }, [editData])

  useEffect(() => {
    if (editData?.CreditorAccountID) {
      loadAllocationDocument({
        variables: {
          entityID: ID,
          creditorDebtorID: editData?.CreditorAccountID,
          companyID: CompanyID,
          refTable: refTable,
        },
      })
    }
  }, [allocatedObj, editData])

  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */

  const {
    handleMutation,
    mutationLoading,
    mutationCalled,
  } = useAPCreateUpdateMutation({
    docType: docType,
    mode: mode,
    CompanyID: CompanyID,
    isSubmit: isSubmit,
    setErrorDia,
    setErrMsg,
  })

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'Allocation',
  })

  /* -------------------------------------------------- */
  /*                   DOC NO CHECKING                  */
  /* -------------------------------------------------- */

  //checking for existing docNo
  const str = watch('DocNo')

  const existingDocNo = creditorAcc?.find(
    x => x?.CreditorAccountID === watch('CreditorAccountID')
  )?.DocNoList

  const findDuplicates = arr => {
    if (
      (formMode === 'add' && arr?.includes(str)) ||
      (formMode === 'edit' &&
        arr?.filter(x => x !== editData?.DocNo)?.includes(str))
    ) {
      return true
    } else {
      return false
    }
  }

  const checkSetupNumStruc = () => {
    if (
      (getDocumentNumberHeader?.length > 0 &&
        getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true) &&
        getDocumentNumberHeader[0]?.SampleOutput === '') ||
      (getDocumentNumberHeader
        ?.filter(x => x?.RefTable === 'AP_CreditNote')
        ?.find(y => y?.SampleOutput === '') &&
        docNoChecker === true) ||
      (getDocumentNumberHeader?.length === 0 &&
        docNoChecker === true &&
        docType === 'credit-note')
    ) {
      return true
    } else return false
  }

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  const inputData = (data, status) => {
    return {
      ...(docType === 'creditor-credit-note'
        ? { CreditorCreditNoteID: ID }
        : { CreditNoteID: ID }),
      CompanyID: CompanyID,
      DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
      TransactionDate: formatDashDate(new Date(data?.TrxDate)?.toISOString()),
      DocAmt: totalAmount,
      TaxAmt: totalTaxAmt ?? 0,
      Amount: amtBeforeTax ?? 0,
      DocAmtBeforeTax: amtBeforeTax ?? 0,
      ...(docNoChecker === false ? { DocNo: data.DocNo } : null),
      CreditorAccountID: data?.CreditorAccountID,
      Remark: data?.Remark,
      Description: data?.Description,
      // RefTable: creditDebitRefTable,
      ...(watch('CreditDebitID') ? { RefRecordID: data?.CreditDebitID } : null),
      RefNo: data?.RefNo,
      ApprovalStatus: statusInput(status),
      Attachment: files,
    }
  }

  // //To calculate Total Amount of Credit Note
  let totalAmount = creditNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.DocAmt),
    0
  )
  // //To calculate Total Amount of Credit Note
  let totalTaxAmt = creditNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.TaxAmt),
    0
  )

  // // get amount before tax
  let amtBeforeTax = totalAmount - totalTaxAmt
  let calcTotal = items => {
    return items?.reduce(
      (total, currentValue) => (total = total + currentValue.DocAmt),
      0
    )
  }

  const handleCreditorChange = event => {
    setSelectedDocs(new Set())
    remove()

    loadAllocationDocument({
      variables: {
        ...(editData && editData?.CreditorAccountID === event
          ? { entityID: [ID] }
          : null),
        creditorDebtorID: event,
        companyID: CompanyID,
        refTable: refTable,
      },
    })
  }

  const [selectedGRTNs, setSelectedGRTNs] = useState<Set<any>>(new Set([]))

  const handleCheckBoxChange = (event: any) => {
    let temp = selectedGRTNs || new Set()
    if (event?.target?.checked) {
      temp.add(event?.target?.value)
      setSelectedGRTNs(new Set(temp))
      fetchGRTNItem({ variables: { GRTNHeaderID: Array.from(selectedGRTNs) } })
    } else {
      temp.delete(event?.target?.value)
      setSelectedGRTNs(new Set(temp))
    }
    fetchGRTNItem({ variables: { GRTNHeaderID: Array.from(selectedGRTNs) } })
  }

  const getTotalAmt = () => {
    if (creditNoteItemData?.length > 0) {
      return creditNoteItemData.reduce((prevValue, currValue) => {
        return prevValue + currValue?.DocAmt
      }, 0)
    } else {
      return 0
    }
  }
  const getAllocationTotal = () => {
    if (watch('Allocation')?.length > 0) {
      return watch('Allocation').reduce((prevValue, currValue) => {
        return prevValue + currValue?.AllocationAmt
      }, 0)
    } else {
      return 0
    }
  }

  const getBalanceAmt = () => {
    if (watch('Allocation')?.length > 0) {
      return watch('Allocation')?.reduce((prevValue, currValue) => {
        return prevValue + currValue?.BalanceAmt
      }, 0)
    } else {
      return 0
    }
  }

  const docAmtChecker = () => {
    if (getTotalAmt > getBalanceAmt) {
      return true
    } else {
      return false
    }
  }
  const readOnly = () => {
    if (menu?.obj?.DOItemID || menu?.obj?.GRTNItemID) return true
    else return false
  }

  /* -------------------------------------------- */
  /*                USE EFFECT                    */
  /* -------------------------------------------- */

  useEffect(() => {
    if (ID && editData?.Remark) {
      setValue('Remark', editData?.Remark)
    }
  }, [editData])

  //   this is where i get the data for item when editing the form

  useEffect(() => {
    if (formMode === 'edit' && editData) {
      fetchGeneralGRTN({
        variables: {
          CreditorAccountID: editData?.CreditorAccountID,
          CompanyID,
          GRTNItemIDs: editData?.APCreditorCreditNoteItem?.filter?.(
            y => y?.GRTNItemID !== null
          )?.map(z => z?.GRTNItemID),
        },
      })
      const creditNoteItems = editData?.[itemRefTable]?.map((el, index) => {
        return {
          Sequence: el?.Sequence ?? index + 1,
          ...(docType === 'creditor-credit-note'
            ? {
                CreditorCreditNoteItemID: el?.CreditorCreditNoteItemID,
              }
            : {
                CreditNoteItemID: el?.CreditNoteItemID,
              }),
          ...(docType === 'creditor-credit-note'
            ? {
                GRTNItemID: el?.GRTNItemID,
              }
            : null),
          ExpenseItemName: el?.ExpenseItem?.ItemName,
          ExpenseItemID: el?.ExpenseItemID,
          CostCentreCode: el?.CostCentre?.Code,
          CostCentreID: el?.CostCentreID,
          Amount: el?.Amount,
          Quantity: Number(parseFloat(el?.Quantity).toFixed(4)),
          UnitPrice: Number(parseFloat(el?.UnitPrice).toFixed(4)),
          UOMID: el?.UOMID,
          TaxSchemeID: el?.TaxSchemeID,
          TaxCode: el?.TaxScheme?.Code,
          TaxAmt: el?.TaxAmt,
          TaxRate: el?.TaxRate,
          Remark: el?.Remark ?? el?.Remarks ?? '-',
          DocAmt: el?.Amount + el?.TaxAmt,
          Description: '-',
        }
      })

      creditNoteItemData.push(...creditNoteItems)
      if (editData?.GRTN?.length > 0) {
        editData?.GRTN?.map(x => selectedGRTNs.add(x?.GRTNHeaderID))
      }
    }
  }, [formMode, editData])

  /* -------------------------------------------- */
  /*                   DELETE                     */
  /* -------------------------------------------- */

  const onDeleteCreditNoteItem = (delInd: number) => {
    creditNoteItemData.splice(delInd, 1)
  }

  /* ------------------------------------------- */
  /*                ON SUBMIT                    */
  /* ------------------------------------------- */
  // updated tax effective date

  const updateNewCNItem = (newDate, tED) => {
    const arr = []
    creditNoteItemData?.map(x => {
      let newTax =
        getTaxEffectiveDate?.filter(
          j =>
            j?.TaxSchemeID === x?.TaxSchemeID &&
            new Date(j?.Date) <= new Date(newDate)
        )?.length > 0
          ? getTaxEffectiveDate
              ?.filter(
                j =>
                  j?.TaxSchemeID === x?.TaxSchemeID &&
                  new Date(j?.Date) <= new Date(newDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })
          : null

      if (newTax !== null) {
        arr.push({
          CreditNoteItemID: x?.CreditNoteItemID,
          ...(docType === 'creditor-credit-note'
            ? {
                GRTNItemID: x?.GRTNItemID,
                CreditorCreditNoteItemID: x?.CreditorCreditNoteItemID,
              }
            : null),
          ExpenseItemID: x?.ExpenseItemID,
          RevenueCode: x?.RevenueCode,
          RevenueName: x?.RevenueName,
          Amount: x?.Amount,
          Quantity: Number(parseFloat(x?.Quantity).toFixed(4)),
          UnitPrice: Number(parseFloat(x?.UnitPrice).toFixed(4)),
          UOMID: x?.UOMID,
          TaxSchemeID: x?.ExpenseItem?.TaxSchemeID,
          TaxCode: x?.TaxCode,
          TaxRate: newTax?.Rate,
          TaxAmt: x?.Amount * (newTax?.Rate / 100),
          DocAmt: x?.Amount + x?.Amount * (newTax?.Rate / 100),
          CostCentreID: x?.CostCentreID,
          CostCentreCode: x?.CostCentreCode,
          Remark: x?.Remark,
          Sequence: x?.Sequence,
        })
      }
    })
  }

  const onSubmit = (data, status) => {
    if (checkSetupNumStruc() === true) {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.errorNumberingStructure())
    }
    if (
      docType === 'creditor-credit-note' &&
      findDuplicates(existingDocNo) === true
    ) {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.duplicateDocNo())
    } else {
      //add the data with GraphQL mutation
      handleMutation({
        variables: {
          input: inputData(data, status),
          itemInput:
            creditNoteItemData?.map(x => {
              return {
                ...(docType === 'creditor-credit-note'
                  ? {
                      CreditorCreditNoteID: ID,
                    }
                  : {
                      CreditNoteID: ID,
                    }),
                ...(docType === 'creditor-credit-note'
                  ? {
                      GRTNItemID: x?.GRTNItemID,
                    }
                  : null),
                ItemID: x?.CreditItemID,
                ExpenseItemID: x?.ExpenseItemID,
                Amount: parseFloat(x?.Amount),
                Quantity: Number(parseFloat(x?.Quantity).toFixed(4)),
                UnitPrice: Number(parseFloat(x?.UnitPrice).toFixed(4)),
                UOMID: x?.UOMID,
                TaxSchemeID: x?.TaxSchemeID,
                TaxRate: parseFloat(x?.TaxRate),
                TaxAmt: parseFloat(x?.TaxAmt),
                DocAmt: Number(x?.DocAmt),
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                Sequence: x?.Sequence,
              }
            }) ?? [],
          allocationInput:
            watch('Allocation')
              ?.filter(
                alloc =>
                  Number(alloc?.AllocationAmt) !== 0 || alloc?.AllocationID
              )
              ?.map(x => {
                const mainCreditOrDebit =
                  x?.CreditOrDebit === 'Debit' ? 'Credit' : 'Debit'
                return {
                  ...(x?.AllocationID
                    ? { AllocationID: x?.AllocationID }
                    : null),
                  AllocationAmt: Number(x?.AllocationAmt),
                  CreditOrDebit: x?.CreditOrDebit,
                  CompanyID: CompanyID,
                  // for allocated table
                  [`${x?.CreditOrDebit}RefTable`]: x?.[
                    `${x?.CreditOrDebit}RefTable`
                  ],
                  [`${x?.CreditOrDebit}ID`]: x?.[`${x?.CreditOrDebit}ID`],
                  // main table
                  [`${mainCreditOrDebit}RefTable`]: refTable,
                }
              }) ?? [],
        },
      })
    }
  }

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const trxDateTimestamp = new Date(watch('TrxDate'))
  const yearTrxDate = trxDateTimestamp.getFullYear()
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`

  const openPeriod1 =
    transferDate <= latestOpenPeriodCheckingDate?.StartDate ||
    transferDate >= latestOpenPeriodCheckingDate?.EndDate

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? true : false
  const checkingYearClose2 = openPeriod2 ? false : true

  const createUpdateCalled = mutationCalled

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()

  const loading = getItemByCompany
    ? ExpenseItemLoading
    : getCostCentre
    ? CostCentreLoading
    : ''
  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */
  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      checkingYearClose2 ||
      handlePermDisabled({
        companyID: CompanyID,
        permEnum:
          docType === 'creditor-credit-note'
            ? AcctPermission.AccPayableCreditorCreditNotesDraft
            : AcctPermission.AccPayableCreditNotesDraft,
      })
        ? true
        : false,
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      !!errors?.DocDate ||
      !!errors?.TrxDate ||
      !!errors?.RefNo ||
      !!errors?.CreditorAccountID ||
      !!errors?.Description ||
      checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum:
              docType === 'creditor-credit-note'
                ? AcctPermission.AccPayableCreditorCreditNotesUpdate
                : AcctPermission.AccPayableCreditNotesUpdate,
          })
        ? true
        : false,
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: e => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
      setIsSubmit(true)
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      calcTotal(creditNoteItemData) === 0 ||
      calcTotal(creditNoteItemData) === undefined ||
      checkingYearClose2 === true ||
      (getAllocationTotal() !== calcTotal(creditNoteItemData) && isSelfBilled)
        ? true
        : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */

  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: formMode,

      // for item
      itemSuffixID: itemRefTableKey,
      itemTableName: itemRefTable,
      // for attachment
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
    })

  return (
    <>
      {loading && <Loading />}
      {CompanyLoading && <Loading />}
      {documentLoading && <Loading />}
      {mutationLoading && <Loading />}
      {ItemGRTNsLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {TaxEffectiveDateLoading && <Loading />}
      {DocDateValidationLoading && <Loading />}
      {CreditorAccountCompanyAssignmentLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          {
            if (hasChanges() === true) {
              setOpenExitConf(true)
            } else {
              history.push({
                pathname: `/account-payable/${CompanyID}/${docType}`,
              })
            }
          }
        }}
        smTitle={'Accounts Payable'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Accounts Payable Main Menu' },
          { name: 'Accounts Payable Submenu' },
          { name: routeSegments, current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : formMode === 'edit'
                ? 'Edit'
                : 'Draft',
            current: true,
          },
        ]}
      />
      <ContentWrapper footer float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="DocDate"
              required
              label="Doc Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              onAccept={(date: Date | null) => {
                updateNewCNItem(date, taxEffectiveList)
              }}
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              showTodayButton
              className={'left'}
              value={watch(formMode === 'add' ? new Date() : editData?.DocDate)}
              defaultValue={editData ? editData?.DocDate : new Date()}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose2
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose2 ? true : false}
            />
          </MuiPickersUtilsProvider>

          {(docNoChecker === false && docType === 'creditor-credit-note') ||
          (getDocumentNumberHeader[0]?.IsAutoDocNo === false &&
            refTable === 'creditor-credit-note') ? (
            <Controller
              as={TextField}
              name="DocNo"
              label="Document No"
              margin="dense"
              required
              id="standard-basic"
              defaultValue={editData?.DocNo}
              autoComplete="off"
              control={control}
              className="left"
              ref={register}
              helperText={errors?.DocNo?.message}
              error={errors?.DocNo ? true : false}
            />
          ) : null}

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            required
            autoComplete="off"
            control={control}
            className={
              (docNoChecker === false && docType === 'creditor-credit-note') ||
              (getDocumentNumberHeader[0]?.IsAutoDocNo === false &&
                docType === 'creditor-credit-note')
                ? 'right'
                : ''
            }
            fullWidth={
              (docNoChecker === false && docType === 'creditor-credit-note') ||
              (getDocumentNumberHeader[0]?.IsAutoDocNo === false &&
                docType === 'creditor-credit-note')
                ? true
                : false
            }
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            defaultValue={editData ? editData?.RefNo : ''}
            disabled={mode == 'approve-reject'}
          />

          {!CreditorAccountCompanyAssignmentLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = creditorAcc?.filter(
                  x => x?.CreditorAccountID === editData?.CreditorAccountID
                )[0]

                return (
                  <Autocomplete
                    options={
                      creditorAcc?.sort((a, b) => {
                        return a.CompanyName.localeCompare(b.CompanyName)
                      }) || []
                    }
                    getOptionLabel={option => `${option?.CompanyName}`}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      handleCreditorChange(newValue?.CreditorAccountID)
                      setValue('CreditorAccountID', newValue?.CreditorAccountID)
                      clearErrors('CreditorAccountID')
                      setSelectedGRTNs(new Set([]))
                      setCreditNoteItemData([])
                      fetchGeneralGRTN({
                        variables: {
                          CreditorAccountID: newValue?.CreditorAccountID,
                          CompanyID: CompanyID,
                          GRTNItemIDs: editData?.APCreditorCreditNoteItem?.map(
                            z => z?.GRTNItemID
                          ),
                        },
                      })
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.CompanyName}</span>
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CreditorAccountID?.message}
                            error={errors?.CreditorAccountID ? true : false}
                            label="Creditor Name"
                            style={{ width: '100%' }}
                            margin="dense"
                            required
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Creditor Name"
              name="CreditorAccountID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="dense"
              ref={register}
              helperText={errors?.CreditorAccountID?.message}
              error={errors?.CreditorAccountID ? true : false}
              defaultValue={editData?.CreditorAccountID}
              required
              disabled={mode === 'approve-reject'}
            />
          )}

          {docType === 'creditor-credit-note' && !GeneralGRTNLoading && (
            <Controller
              render={({ value, onChange }) => {
                return (
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    options={
                      getGeneralGRTN?.sort((a, b) => {
                        return a.DocNo.localeCompare(b.DocNo)
                      }) || []
                    }
                    getOptionLabel={option => `${option?.DocNo}`}
                    fullWidth
                    onChange={(event, value, reason) => {
                      const updatedSelected = new Set(
                        value?.map(x => x?.DOHeaderID)
                      )

                      setSelectedGRTNs(updatedSelected)

                      // Fetch updated DO items
                      fetchGRTNItem({
                        variables: {
                          GRTNHeaderID: Array.from(updatedSelected),
                        },
                      })

                      // Clear invoice items if last DO is removed
                      if (reason === 'clear' || updatedSelected.size === 0) {
                        setCreditNoteItemData([])
                      }
                    }}
                    defaultValue={editData?.GRTN}
                    renderOption={(option, { selected }) => (
                      <Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          value={option?.GRTNHeaderID}
                          style={{ marginRight: 8 }}
                          checked={selectedGRTNs.has(option?.GRTNHeaderID)}
                          color="primary"
                          onChange={event => {
                            handleCheckBoxChange(event)
                          }}
                        />
                        <ListItemText
                          primary={
                            <Grid container>
                              <Grid
                                item
                                xs={8}
                                component="span"
                                className="xsTitle "
                              >
                                {option?.DocNo}
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                component="span"
                                className="xsTitle right-text highlight-text"
                              >
                                {amtStr(option?.ReturnedAmt)}
                              </Grid>
                            </Grid>
                          }
                          secondary={
                            <Grid container>
                              <Grid
                                item
                                xs={8}
                                component="span"
                                className="desc"
                                style={{
                                  wordWrap: 'break-word',
                                  wordBreak: 'break-word',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {option?.PONo}
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                component="span"
                                className="xsTitle right-text highlight-text"
                              ></Grid>
                            </Grid>
                          }
                        />
                      </Fragment>
                    )}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            variant="outlined"
                            style={{ width: '100%' }}
                            label="GRTN No."
                            margin="normal"
                            onKeyDown={(event: any) => {
                              if (event.key === 'Backspace') {
                                event.stopPropagation()
                                setSelectedGRTNs(new Set())
                              }
                            }}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              name="GRTNHeaderID"
              label="GRTN No."
              margin="normal"
              autoComplete="off"
              fullWidth
              multiline={true}
              control={control}
              select
              ref={register}
            />
          )}

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            defaultValue={editData?.Description}
            ref={register}
            disabled={mode === 'approve-reject'}
          />

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />

          <div style={{ width: '100%', marginTop: '24px' }}>
            <FileUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              name="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>
        </CardContents>

        <ItemDetail
          CN={true}
          titleLabel={'Charge to'}
          itemData={creditNoteItemData}
          setOpenDialog={setOpenItemDialog}
          fetchExpenseItem={fetchExpenseItem}
          fetchCostCentre={fetchCostCentre}
          resetMenu={resetMenu}
          setSelectedTax={setSelectedTax}
          setDetailMode={setDetailMode}
          formMode={formMode}
          detailMode={detailMode}
          getItemByCompany={getItemByCompany}
          getCostCentre={getCostCentre}
          handleClick={handleClick}
          keyItemID={itemRefTableKey}
          isSelfBilled={isSelfBilled}
          creditorID={selectedCreditor}
        />

        {watch('CreditorAccountID') && !getAllocationDocumentLoading && (
          <FormAllocationTableContent
            headerLeftTitle={'Allocation'}
            fields={fields}
            watch={watch}
            errors={errors}
            register={register}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            setError={setError}
            allocatedObj={allocatedObj}
            docAmt={getTotalAmt()}
            totalAmt={getAllocationTotal()}
            headerStyle={{
              backgroundColor: '#faf2e8',
              color: '#ff9800',
              fontWeight: '700',
            }}
            hasCheckbox={true}
          />
        )}
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setEdit(true)
            setOpenItemDialog(true)
            setDetailMode('edit')
            fetchExpenseItem()
            // fetchCostCentre()
          }}
        >
          <span className="">Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteCreditNoteItem(menu?.index)
          }}
          disabled={readOnly() === true ? true : false}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>

      {openItemDialog && (
        <ItemDialog
          title={'Charge to'}
          companyID={CompanyID}
          fromAP={true}
          openItemDialog={openItemDialog}
          setOpenItemDialog={setOpenItemDialog}
          watch={watch}
          menu={menu}
          ExpenseItemLoading={ExpenseItemLoading}
          fetchExpenseItem={fetchExpenseItem}
          getItemByCompany={getItemByCompany}
          isEdit={edit}
          itemData={creditNoteItemData}
          selectedTax={selectedTax}
          setSelectedTax={setSelectedTax}
          detailMode={detailMode}
          setDetailMode={setDetailMode}
          resetMenu={resetMenu}
          primaryItemKey={itemRefTableKey}
          selfBillStatus={isSelfBilled}
          creditorAcc={selectedCreditor}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-payable/${CompanyID}/${docType}`)
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      <TooltipAmountFooter
        data={creditNoteItemData}
        module={`${docType}-draft`}
      />
    </>
  )
}
