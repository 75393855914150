import { Grid } from '@material-ui/core'
import { AttachFile } from '@material-ui/icons'
import PrintIcon from '@material-ui/icons/Visibility'
import { AttachmentDialog } from 'components/Dialog/AttachmentDialog'
import { useDocumentListingQuery } from 'generated/graphql'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { default as React, useState } from 'react'
import { useHistory, useParams } from 'react-router'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  docType?: string
  mode?: string
  itemQueryData?: string
}

export const APDetailExpansion = (props: DetailProps) => {
  const { listEl, documentListing, userList, docType, itemQueryData } = props
  let history = useHistory()
  const { CompanyID }: any = useParams()

  let primaryKey
  switch (docType) {
    case 'advance':
      primaryKey = 'AdvanceID'
      break
    case 'invoice':
      primaryKey = 'InvoiceID'
      break
    case 'credit-note':
      primaryKey = 'CreditNoteID'
      break
    case 'creditor-credit-note':
      primaryKey = 'CreditorCreditNoteID'
      break
    case 'creditor-debit-note':
      primaryKey = 'CreditorDebitNoteID'
      break
    case 'debit-note':
      primaryKey = 'DebitNoteID'
      break
    case 'payment':
      primaryKey = 'PaymentID'
      break
    case 'refund':
      primaryKey = 'RefundID'
      break

    default:
      break
  }

  const {
    loading: documentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: listEl?.[`${primaryKey}`],
    },
  })

  const [openDoc, setOpenDoc] = useState<boolean>(false)
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu: resetVert,
  } = useMenuOption()

  return (
    <>
      {(docType === 'advance' ||
        docType === 'refund' ||
        docType === 'payment') && (
        <>
          <Grid container>
            <Grid item xs={10}>
              <div className="content-wrap full">
                <div className="desc">Creditor Name</div>
                <div className="xsTitle">
                  {listEl?.CreditorAccount?.CompanyName}
                </div>
              </div>
              <Grid container>
                <Grid item xs={6}>
                  <div>
                    <div className="desc">Reference No</div>
                    <div className="xsTitle text-noflow">{listEl?.RefNo}</div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ placeSelf: 'end', textAlign: 'right' }}
                >
                  <div>
                    <div className="desc">{'Transaction Date'}</div>
                    <div
                      className="mdDesc text-noflow"
                      style={{ fontSize: '11px' }}
                    >
                      {formatDate(listEl?.TransactionDate)}
                    </div>
                  </div>
                </Grid>
              </Grid>

              <div className="content-wrap full">
                <div className="desc">Description</div>
                <div className="xsTitle text-noflow">{listEl?.Description}</div>
              </div>

              {listEl?.Remark && (
                <div className="content-wrap full">
                  <div className="desc">Remark</div>
                  <div className="xsTitle text-noflow">{listEl?.Remark}</div>
                </div>
              )}
            </Grid>
            <Grid item xs={2}>
              <span
                style={{ float: 'right' }}
                onClick={() => {
                  history.push({
                    pathname: `/account-payable/${CompanyID}/${docType}/${
                      listEl?.[`${primaryKey}`]
                    }/preview`,
                    state: {
                      ...listEl,
                      mode: 'detail',
                    },
                  })
                }}
              >
                <PrintIcon
                  style={{
                    fontSize: 'medium',
                    padding: '0px 10px 2px 0px',
                  }}
                />
              </span>
              {documentListing && documentListing?.length > 0 && (
                <>
                  <span
                    style={{ float: 'right', color: '#000' }}
                    onClick={() => {
                      setOpenDoc(true)
                    }}
                  >
                    <AttachFile
                      className="mdDesc"
                      style={{
                        fontSize: 12,
                        color: 'orange',
                      }}
                    />

                    <span
                      className="mdDesc"
                      style={{
                        float: 'right',
                        color: 'orange',
                        textDecoration: 'underline',
                        paddingRight: '5px',
                      }}
                    >
                      {documentListing?.length}
                    </span>
                  </span>
                </>
              )}
            </Grid>
          </Grid>

          <div className="content-wrap left">
            {docType === 'refund' ? (
              <div className="desc">Refund Method</div>
            ) : (
              <div className="desc">Payment Method</div>
            )}

            <div className="xsTitle">{listEl?.PaymentMethod?.Name}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Bank Account No.</div>
            <div className="xsTitle" style={{ whiteSpace: 'break-spaces' }}>
              {`${listEl?.BankAccount?.AccountNo} (${listEl?.BankAccount?.BankProfile?.Name})`}
            </div>
          </div>

          {listEl?.PaymentMethod?.Name?.includes('Card') && (
            <div className="content-wrap right">
              <div className="desc">Card Type</div>
              <div className="xsTitle">{listEl?.CreditCardType?.Name}</div>
            </div>
          )}

          {listEl?.PaymentMethod?.Name === 'Cheque' && (
            <div className="content-wrap right">
              <div className="desc">Cheque No.</div>
              <div className="xsTitle">{listEl?.ChequeNo}</div>
            </div>
          )}

          {listEl?.PaymentMethod?.Name === 'Cheque' && (
            <div className="content-wrap left">
              <div className="desc">Cheque Date</div>
              <div className="xsTitle">{formatDate(listEl?.ChequeDate)}</div>
            </div>
          )}

          {listEl?.PaymentMethod?.Name === 'Cheque' && (
            <div className="content-wrap full">
              <div className="desc">Cheque Expiry Date</div>
              <div className="xsTitle">
                {formatDate(listEl?.ChequeExpiryDate)}
              </div>
            </div>
          )}
        </>
      )}

      {(docType === 'debit-note' ||
        docType === 'invoice' ||
        docType === 'creditor-credit-note' ||
        docType === 'credit-note') && (
        <>
          <Grid container>
            <Grid item xs={10}>
              <div className="content-wrap left">
                <div className="desc">Reference No.</div>
                <div className="xxTitle text-noflow">{listEl?.RefNo}</div>
              </div>
              {docType === 'invoice' && listEl?.DO?.length !== 0 && (
                <div className="content-wrap right ">
                  <div className="desc">DO No.</div>
                  <div className="xxTitle">
                    {' '}
                    {listEl?.DO?.map(x => x?.DocNo).join(', ') ?? ''}{' '}
                  </div>
                </div>
              )}

              {docType === 'creditor-credit-note' &&
                listEl?.GRTN?.length !== 0 && (
                  <div className="content-wrap right ">
                    <div className="desc">GRTN No.</div>
                    <div className="xxTitle">
                      {' '}
                      {listEl?.GRTN?.map(x => x?.DocNo).join(', ') ?? ''}{' '}
                    </div>
                  </div>
                )}

              <div className="content-wrap full">
                <div className="desc">{'Transaction Date'}</div>
                <div
                  className="xxTitle text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {formatDate(listEl?.TransactionDate)}
                </div>
              </div>
              {listEl?.Description && (
                <>
                  <div className="content-wrap full">
                    <div className="desc">Description</div>
                    <div className="xxTitle text-noflow">
                      {listEl?.Description}
                    </div>
                  </div>
                </>
              )}
              {listEl?.Remark && (
                <div className="content-wrap full">
                  <div className="desc">Remark</div>
                  <div className="xxTitle text-noflow">{listEl?.Remark}</div>
                </div>
              )}
            </Grid>
            <Grid item xs={2}>
              {(docType === 'credit-note' || docType === 'debit-note') && (
                <>
                  <span
                    style={{ float: 'right' }}
                    onClick={() => {
                      history.push({
                        pathname: `/account-payable/${CompanyID}/${docType}/${
                          listEl?.[`${primaryKey}`]
                        }/preview`,
                        state: {
                          ...listEl,
                          mode: 'detail',
                        },
                      })
                    }}
                  >
                    <PrintIcon
                      style={{
                        fontSize: 'medium',
                        padding: '0px 10px 2px 0px',
                      }}
                    />
                  </span>
                  <br />
                </>
              )}
              {documentListing && documentListing?.length > 0 && (
                <>
                  <div style={{ float: 'right', alignSelf: 'end' }}>
                    {documentListing && (
                      <>
                        <span
                          onClick={() => {
                            setOpenDoc(true)
                          }}
                          style={{
                            position: 'relative',
                            top: '1px',
                          }}
                        >
                          <AttachFile
                            className="mdDesc"
                            style={{
                              fontSize: 12,
                              color: 'orange',
                            }}
                          />
                        </span>
                        <span
                          className="mdDesc"
                          style={{
                            color: 'orange',
                            textDecoration: 'underline',
                          }}
                        >
                          {documentListing?.length}
                        </span>
                      </>
                    )}
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow">
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow">
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>

      <AttachmentDialog
        title="Payment"
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        attachmentDetailView={attachmentDetailView}
        DocumentListing={DocumentListing}
      />
    </>
  )
}
