import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import ShareIcon from '@material-ui/icons/Share'
import { Pagination } from '@material-ui/lab'
import { Group, drawDOM, exportPDF } from '@progress/kendo-drawing'
import ShareDialog from 'components/Dialog/ShareDialog'
import saveAs from 'file-saver'
import {
  useGetCompanyQuery,
  useGetShareAttachmentMutation,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useMobilePDFZoom } from 'helpers/Hooks/useMobilePDFZoom'
import * as htmlToImage from 'html-to-image'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import './PDFStyle.scss'
import { useQueries } from './Query/useQueries'
import ELetterPreview from './ELetterTemplate/ELetterPreview'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

export const PDFPreviewV2 = (props: any) => {
  const { accountType, docType } = props
  let history = useHistory()
  let location = useLocation()

  const {
    CompanyID,
    InvoiceID,
    ReceiptID,
    CreditNoteID,
    DebitNoteID,
    PaymentID,
    AdvanceID,
    RefundID,
    BatchPaymentID,
  }: any = useParams()

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    loading: companyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 7
  } else {
    fontSize = 10
  }

  let fontStyle
  fontStyle = {
    fontSize: fontSize,
    textAlign: 'center',
  }

  let routeSegments
  let title
  let smallTitle
  let creditorOrDebtor
  let name
  let DocumentID
  let itemName
  let hasItem: boolean
  switch (accountType) {
    case 'account-receivable':
      smallTitle = smTitle.ACCOUNT_RECEIVABLE
      creditorOrDebtor = 'DebtorAccount'
      name = 'DebtorName'
      switch (docType) {
        case 'official-receipt':
          DocumentID = ReceiptID
          routeSegments = 'Official Receipt'
          title = 'OFFICIAL RECEIPT'
          itemName = 'Allocation'
          hasItem = true
          break
        case 'refund':
          DocumentID = RefundID
          routeSegments = 'Refund'
          title = 'REFUND VOUCHER'
          itemName = 'Allocation'
          hasItem = true
          break
        case 'invoice':
          DocumentID = InvoiceID
          routeSegments = 'Invoice'
          title = 'INVOICE'
          itemName = 'ARInvoiceItem'
          hasItem = true
          break
        case 'credit-note':
          DocumentID = CreditNoteID
          routeSegments = 'Credit Note'
          title = 'CREDIT NOTE'
          itemName = 'ARCreditNoteItem'
          hasItem = true
          break
        case 'debit-note':
          DocumentID = DebitNoteID
          routeSegments = 'Debit Note'
          title = 'DEBIT NOTE'
          itemName = 'ARDebitNoteItem'
          hasItem = true
          break
      }
      break
    case 'account-payable':
      smallTitle = smTitle.ACCOUNT_PAYABLE
      creditorOrDebtor = 'CreditorAccount'
      name = 'CompanyName'
      switch (docType) {
        case 'payment':
          DocumentID = PaymentID
          routeSegments = !!BatchPaymentID ? 'Batch Payment' : 'Payment'
          title = 'PAYMENT VOUCHER'
          itemName = 'Allocation'
          hasItem = true
          break
        case 'advance':
          DocumentID = AdvanceID
          routeSegments = 'Advance'
          title = 'ADVANCE VOUCHER'
          hasItem = false
          break
        case 'refund':
          DocumentID = RefundID
          routeSegments = 'Refund'
          title = 'REFUND VOUCHER'
          itemName = 'Allocation'
          hasItem = true
          break
        case 'invoice':
          DocumentID = InvoiceID
          routeSegments = 'Invoice'
          title = 'INVOICE'
          itemName = 'APInvoiceItem'
          hasItem = true
          break
        case 'credit-note':
          DocumentID = CreditNoteID
          routeSegments = 'Credit Note'
          title = 'CREDIT NOTE'
          itemName = 'APCreditNoteItem'
          hasItem = true
          break
        case 'debit-note':
          DocumentID = DebitNoteID
          routeSegments = 'Debit Note'
          title = 'DEBIT NOTE'
          itemName = 'APDebitNoteItem'
          hasItem = true
          break
      }
      break
  }

  const { loading, listData, primaryKey, templateBody } = useQueries({
    accountType,
    docType,
    CompanyID,
    ReceiptID,
    DocumentID: DocumentID,
    setErrDialog,
    setErrMessage,
  })

  const previewDetail: any = listData?.filter(
    v => v?.[primaryKey] === DocumentID
  )[0]

  // ========== Page definition ==========

  const [page, setCurrentPage] = useState<number>(0)

  useEffect(() => {
    if (!!previewDetail && remapData === null) separateThreshold(previewDetail)
  }, [previewDetail])

  // ========== Remap Data for item list ==========
  const [remapData, setRemapData] = useState(null)

  const separateThreshold = input => {
    if (!!hasItem) {
      let temp = []
      let noChange = []
      let tempItem = []

      previewDetail?.[itemName]?.map((v, i) => {
        tempItem.push({ ...v, index: i + 1 })
        if ((i + 1) % 30 === 0 && temp?.length === 0) {
          temp.push({ ...input, [itemName]: tempItem })
          tempItem = []
        } else if ((i + 1) % threshold === 0) {
          temp.push({ ...input, [itemName]: tempItem })
          tempItem = []
        } else if (previewDetail?.[itemName]?.length === i + 1) {
          temp.push({ ...input, [itemName]: tempItem })
        }
      })
      noChange.push(temp)
      temp = []
      return setRemapData(noChange.flat())
    }
  }

  // ========== Threshold definition ==========
  let totalItems

  if (hasItem === false) totalItems = 1
  else totalItems = previewDetail?.[itemName]?.length

  let threshold
  if (totalItems > 25) {
    if (page !== remapData?.length + 1) threshold = 25
    else threshold = 20
  } else {
    threshold = 0
  }

  /// ========== kendo-drawing PDF Download ===========
  const dataURItoFile = (dataurl, filename) => {
    dataurl.replace('data:application/pdf;base64,', '')
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const onPrint = () => {
    let element = document.getElementById('pdf-data-print')

    drawDOM(element, {
      paperSize: 'A4',
      // margin: {
      //   bottom: width < 769 ? '0.5cm' : '1cm',
      //   top: width < 769 ? '0.5cm' : '1cm',
      //   left: width < 769 ? '0.25cm' : '0.5cm',
      //   right: width < 769 ? '0.25cm' : '0.5cm',
      // },
      scale: 0.76,
      forcePageBreak: '.page-break',
    })
      .then((group: Group) => exportPDF(group))
      .then(dataURI => {
        let fileObject = dataURItoFile(
          dataURI,
          `${title} (${previewDetail?.DocNo}).pdf`
        )
        saveAs(fileObject, `${title} (${previewDetail?.DocNo}).pdf`)
      })
  }

  const pdfToPrint = () => {
    return (
      <>
        {/* ========================================= */}
        {/* DOCS THAT IS SAVED AS PDF WHICH IS HIDDEN */}
        {/* ========================================= */}

        <div style={{ position: 'fixed', left: `-1000px` }}>
          <div id={'pdf-data-print'}>
            {!!hasItem ? (
              previewDetail &&
              remapData &&
              remapData?.map((v, index) => {
                return (
                  <ELetterPreview
                    fontStyle={fontStyle}
                    title={title}
                    previewDetail={previewDetail}
                    accountType={accountType}
                    docType={docType}
                    itemName={itemName}
                    creditorOrDebtor={creditorOrDebtor}
                    getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
                    remapData={remapData}
                    page={index}
                    pageLength={remapData?.length}
                    hasItem={hasItem}
                    currencyCode={getCompany[0]?.Currency?.code}
                    htmlTemplate={templateBody}
                  />
                )
              })
            ) : (
              <ELetterPreview
                fontStyle={fontStyle}
                title={title}
                previewDetail={previewDetail}
                accountType={accountType}
                docType={docType}
                itemName={itemName}
                creditorOrDebtor={creditorOrDebtor}
                getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
                remapData={remapData}
                page={page} // For hasItem === false
                pageLength={1} // For hasItem === false
                hasItem={hasItem}
                currencyCode={getCompany[0]?.Currency?.code}
                htmlTemplate={templateBody}
              />
            )}
          </div>
        </div>
      </>
    )
  }

  const [labelState, setLabelState] = useState<string>()

  useEffect(() => {
    setLabelState(getCompany[0]?.Name)
  }, [getCompany])

  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [openSendEmail, setOpenSendEmail] = useState(false)

  const [
    getShareAttachments,
    {
      data: { getShareAttachment } = { getShareAttachment: {} as any },
      called: getShareAttachmentCalled,
      loading: ShareAttachmentLoading,
    },
  ] = useGetShareAttachmentMutation({
    onCompleted: () => {
      setOpenShareDialog(true)
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const onSubmit = blob => {
    getShareAttachments({
      variables: { file: blob, CompanyID: CompanyID },
    })
  }

  return (
    <>
      {loading && <Loading />}
      {companyLoading && <Loading />}
      {ShareAttachmentLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          !!BatchPaymentID
            ? history.push({
                pathname: `/${accountType}/${CompanyID}/batch-payment/${BatchPaymentID}/detail`,
                state: previewDetail,
              })
            : previewDetail?.ApprovalStatus === 'COMPLETED'
            ? history.push({
                pathname: `/${accountType}/${CompanyID}/${docType}/${DocumentID}/detail`,
                state: previewDetail,
              })
            : history.push({
                pathname: `/${accountType}/${CompanyID}/${docType}`,
                state: previewDetail,
              })
        }}
        smTitle={smallTitle}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: `${smTitle}` },
          { name: `${routeSegments}` },
          { name: `${routeSegments}`, current: true },
        ]}
        rightRouteSegments={[{ name: `Print`, current: true }]}
      />
      <ContentWrapper footer>
        <div>
          <ELetterPreview
            fontStyle={fontStyle}
            title={title}
            previewDetail={previewDetail}
            accountType={accountType}
            docType={docType}
            itemName={itemName}
            creditorOrDebtor={creditorOrDebtor}
            getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
            remapData={remapData}
            page={page}
            pageLength={remapData?.length ?? 1}
            hasItem={hasItem}
            currencyCode={getCompany[0]?.Currency?.code}
            htmlTemplate={templateBody}
            isView={true}
          />
        </div>

        <div
          className={
            width < 769 ? 'pagination-dis-mobile' : 'pagination-dis-desktop'
          }
        >
          <Pagination
            count={remapData?.length}
            shape="rounded"
            page={page + 1}
            onChange={(e, page) => {
              setCurrentPage(page - 1)
            }}
          />
        </div>
      </ContentWrapper>

      <div style={{ width: 1980 }}>{pdfToPrint()}</div>

      {ShareDialog({
        shareTitle: `${smallTitle} - ${labelState}`,
        title: `Share`,
        URL: getShareAttachment?.fileURL,
        setSimple: setOpenShareDialog,
        simple: openShareDialog,
        emailShare: openSendEmail,
        setSimpleEmail: setOpenSendEmail,
      })}

      <Footer
        options={[
          {
            name: 'Share',
            onClick: async () => {
              let temp = await document.getElementById('pdf-data-print')

              htmlToImage
                .toBlob(temp, { style: { background: '#fff' } })
                .then(value => {
                  onSubmit(value)
                })
                .catch(error => console.error(error, 'Something went wrong'))
            },
            // disabled:
            //   graphData.every(emptyData) == undefined ||
            //   graphData.every(emptyData) ||
            //   handlePermDisabled({
            //     permEnum: ContPermission.ProjectCostingProjectCostingShare,
            //   }),
            color: 'primary',
            props: {
              type: 'submit',
            },

            startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
          },
          {
            name: 'Download',
            color: 'primary',
            onClick: () => {
              onPrint()
            },
          },
        ]}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
