import { AcctPermission } from 'generated/graphql'

export interface RenderPermissionTree {
  id: AcctPermission
  label: string
  children?: RenderPermissionTree[]
  layer?: string
  selected?: number
}

export const accessSecurityPerm: RenderPermissionTree = {
  id: AcctPermission.AccessSecurityIcon,
  label: 'Access Security',
  layer: '1',
  children: [
    {
      id: AcctPermission.AccessSecurityUserView,
      label: 'User',
      children: [
        {
          id: AcctPermission.AccessSecurityUserCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccessSecurityUserUpdate,
          label: 'Update',
        },
        // {
        //   id: AcctPermission.AccessSecurityUserChangePassword,
        //   label: 'Change Password',
        // },
        {
          id: AcctPermission.AccessSecurityUserInactive,
          label: 'Active/Inactive',
        },
      ],
    },
    {
      id: AcctPermission.AccessSecurityRoleView,
      label: 'Role',
      children: [
        {
          id: AcctPermission.AccessSecurityRoleCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccessSecurityRoleUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccessSecurityRoleDuplicate,
          label: 'Duplicate',
        },
        {
          id: AcctPermission.AccessSecurityRoleDelete,
          label: 'Delete',
        },
      ],
    },
  ],
}

export const companySetupPerm: RenderPermissionTree = {
  id: AcctPermission.CompanySetupIcon,
  label: 'Company Setup',
  layer: '1',
  children: [
    {
      id: AcctPermission.CompanySetupCreate,
      label: 'Create',
    },
  ],
}

export const generalLedgerPerm: RenderPermissionTree = {
  id: AcctPermission.GeneralLedgerIcon,
  label: 'General Ledger',
  layer: '1',
  children: [
    {
      id: AcctPermission.GeneralLedgerJournalEnquiryView,
      label: 'Journal Enquiry',
    },
    {
      id: AcctPermission.GeneralLedgerOriginalBudgetView,
      label: 'Original Budget',
      children: [
        {
          id: AcctPermission.GeneralLedgerOriginalBudgetCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralLedgerOriginalBudgetUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralLedgerOriginalBudgetDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.GeneralLedgerOriginalBudgetVerified,
          label: 'Verify',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerBudgetRevisionView,
      label: 'Budget Revision',
      children: [
        {
          id: AcctPermission.GeneralLedgerBudgetRevisionCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralLedgerBudgetRevisionUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralLedgerBudgetRevisionDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.GeneralLedgerBudgetRevisionVerified,
          label: 'Verify',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerJournalProcessingView,
      label: 'Journal Entries',
      children: [
        {
          id: AcctPermission.GeneralLedgerJournalProcessingCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralLedgerJournalProcessingUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralLedgerJournalProcessingDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.GeneralLedgerJournalProcessingDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralLedgerJournalProcessingApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerExternalJournalView,
      label: 'External Journal',
      children: [
        {
          id: AcctPermission.GeneralLedgerExternalJournalImport,
          label: 'Import',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerRecurringJournalView,
      label: 'Recurring Journal',
      children: [
        {
          id: AcctPermission.GeneralLedgerRecurringJournalCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralLedgerRecurringJournalUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralLedgerRecurringJournalDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.GeneralLedgerRecurringJournalDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralLedgerRecurringJournalCancel,
          label: 'Cancel',
        },
        {
          id: AcctPermission.GeneralLedgerRecurringJournalApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerGenerateRecurringJournalView,
      label: 'Generate Recurring Journal',
      children: [
        {
          id: AcctPermission.GeneralLedgerGenerateRecurringJournalCreate,
          label: 'Create',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerAuditAdjustmentView,
      label: 'Audit Adjustment',
      children: [
        {
          id: AcctPermission.GeneralLedgerAuditAdjustmentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralLedgerAuditAdjustmentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralLedgerAuditAdjustmentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.GeneralLedgerAuditAdjustmentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralLedgerAuditAdjustmentApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerClosingMonthYearEndView,
      label: 'Month End/ Year End Closing',
    },
    {
      id: AcctPermission.GeneralLedgerDigitalReportsView,
      label: 'Digital Reports',
      children: [
        {
          id: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
          label: 'GL Journal Listing By Account',
        },
        {
          id: AcctPermission.GeneralLedgerDigitalReportsGlJournalCostCentreView,
          label: 'GL Journal Listing By Department',
        },
        {
          id: AcctPermission.GeneralLedgerDigitalReportsTrialBalanceView,
          label: 'Trial Balance by Entity',
        },
        {
          id:
            AcctPermission.GeneralLedgerDigitalReportsTrialBalanceCostCentreView,
          label: 'Trial Balance by Department',
        },
        {
          id: AcctPermission.GeneralLedgerDigitalReportsMasterCoaView,
          label: 'Master COA Listing',
        },
        {
          id: AcctPermission.GeneralLedgerDigitalReportsListingWithStatusView,
          label: 'GL Listing with status',
        },
        {
          id:
            AcctPermission.GeneralLedgerDigitalReportsEntityCoaListingByDepartmentView,
          label: 'Entity COA Listing by Department',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerGeneralSettingsView,
      label: 'General Settings',
      // children: [
      //   {
      //     id: AcctPermission.GeneralLedgerGeneralSettingsAccCodeStructureView,
      //     label: 'Account Code Structure',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralLedgerGeneralSettingsAccCodeStructureCreate,
      //         label: 'Create',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.GeneralLedgerGeneralSettingsMasterCoaView,
      //     label: 'Master COA',
      //     children: [
      //       {
      //         id: AcctPermission.GeneralLedgerGeneralSettingsMasterCoaCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.GeneralLedgerGeneralSettingsMasterCoaUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.GeneralLedgerGeneralSettingsMasterCoaDelete,
      //         label: 'Delete',
      //       },
      //       {
      //         id: AcctPermission.GeneralLedgerGeneralSettingsMasterCoaInactive,
      //         label: 'Inactive',
      //       },
      //       {
      //         id: AcctPermission.GeneralLedgerGeneralSettingsMasterCoaAssign,
      //         label: 'Assign',
      //       },
      //     ],
      //   },
      // ],
    },
    {
      id: AcctPermission.GeneralLedgerCompanySettingsView,
      label: 'Company Settings',
      // children: [
      //   {
      //     id: AcctPermission.GeneralLedgerCompanySettingsCostCentreView,
      //     label: 'Department',
      //     children: [
      //       {
      //         id: AcctPermission.GeneralLedgerCompanySettingsCostCentreCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.GeneralLedgerCompanySettingsCostCentreUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.GeneralLedgerCompanySettingsCostCentreDelete,
      //         label: 'Delete',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralLedgerCompanySettingsCostCentreSetDefault,
      //         label: 'Set as Default',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.GeneralLedgerCompanySettingsEntityCoaView,
      //     label: 'Entity COA',
      //     children: [
      //       {
      //         id: AcctPermission.GeneralLedgerCompanySettingsEntityCoaUnassign,
      //         label: 'Unassign',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.GeneralLedgerCompanySettingsNumberingStructureView,
      //     label: 'Document Numbering',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralLedgerCompanySettingsNumberingStructureCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralLedgerCompanySettingsNumberingStructureUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralLedgerCompanySettingsNumberingStructureDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.GeneralLedgerCompanySettingsJournalTypeView,
      //     label: 'Journal Type',
      //     children: [
      //       {
      //         id: AcctPermission.GeneralLedgerCompanySettingsJournalTypeCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.GeneralLedgerCompanySettingsJournalTypeUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.GeneralLedgerCompanySettingsJournalTypeDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.GeneralLedgerCompanySettingsRetainEarningAccView,
      //     label: 'Retained Earnings Account Settings',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralLedgerCompanySettingsRetainEarningAccCreate,
      //         label: 'Create',
      //       },
      //     ],
      //   },
      //   {
      //     id:
      //       AcctPermission.GeneralLedgerCompanySettingsCompanyAuthorizationView,
      //     label: 'Company User Role Assignment',
      //   },
      // ],
    },
    {
      id: AcctPermission.GeneralLedgerFinancialReportView,
      label: 'Financial Reports',
      children: [
        {
          id: AcctPermission.GeneralLedgerFinancialReportSettingsDataSourceView,
          label: 'Data Source',
          children: [
            {
              id:
                AcctPermission.GeneralLedgerFinancialReportSettingsDataSourceCreate,
              label: 'Create',
            },
            {
              id:
                AcctPermission.GeneralLedgerFinancialReportSettingsDataSourceUpdate,
              label: 'Update',
            },
            {
              id:
                AcctPermission.GeneralLedgerFinancialReportSettingsDataSourceDelete,
              label: 'Delete',
            },
          ],
        },
        {
          id:
            AcctPermission.GeneralLedgerFinancialReportSettingsFormulaToolsView,
          label: 'Formula Tools',
        },
        {
          id:
            AcctPermission.GeneralLedgerFinancialReportSettingsReportFormatView,
          label: 'Report Format',
          children: [
            {
              id:
                AcctPermission.GeneralLedgerFinancialReportSettingsReportFormatCreate,
              label: 'Create',
            },
            {
              id:
                AcctPermission.GeneralLedgerFinancialReportSettingsReportFormatUpdate,
              label: 'Update',
            },
            {
              id:
                AcctPermission.GeneralLedgerFinancialReportSettingsReportFormatDelete,
              label: 'Delete',
            },
            {
              id:
                AcctPermission.GeneralLedgerFinancialReportSettingsReportFormatDownload,
              label: 'Download',
            },
            {
              id:
                AcctPermission.GeneralLedgerFinancialReportSettingsReportFormatUpload,
              label: 'Upload',
            },
          ],
        },
      ],
    },
  ],
}

export const cashBookPerm: RenderPermissionTree = {
  id: AcctPermission.CashBookIcon,
  label: 'Cash Book',
  layer: '1',
  children: [
    {
      id: AcctPermission.CashBookPaymentView,
      label: 'Payment',
      children: [
        {
          id: AcctPermission.CashBookPaymentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.CashBookPaymentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.CashBookPaymentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.CashBookPaymentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.CashBookPaymentApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.CashBookReceiptView,
      label: 'Receipt',
      children: [
        {
          id: AcctPermission.CashBookReceiptCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.CashBookReceiptUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.CashBookReceiptDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.CashBookReceiptDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.CashBookReceiptApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.CashBookBankTransferView,
      label: 'Bank Transfer',
      children: [
        {
          id: AcctPermission.CashBookBankTransferCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.CashBookBankTransferUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.CashBookBankTransferDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.CashBookBankTransferDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.CashBookBankTransferApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.CashBookBankReconcilationView,
      label: 'Bank Reconciliation',
      children: [
        {
          id: AcctPermission.CashBookBankReconcilationCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.CashBookBankReconcilationUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.CashBookBankReconcilationDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.CashBookBankReconcilationMatch,
          label: 'Match',
        },
        {
          id: AcctPermission.CashBookBankReconcilationUnmatch,
          label: 'Unmatch',
        },
      ],
    },
    {
      id: AcctPermission.CashBookExternalLedgerView,
      label: 'External Ledger',
      children: [
        {
          id: AcctPermission.CashBookExternalLedgerImport,
          label: 'Import',
        },
      ],
    },
    {
      id: AcctPermission.CashBookGlExportView,
      label: 'GL Export',
      // children: [
      //   {
      //     id: AcctPermission.CashBookGlExportShare,
      //     label: 'Share',
      //   },
      //   {
      //     id: AcctPermission.CashBookGlExportDownload,
      //     label: 'Download',
      //   },
      // ],
    },
    {
      id: AcctPermission.CashBookDigitalReportView,
      label: 'Digital Reports',
      children: [
        {
          id: AcctPermission.CashBookDigitalReportCashLedgerView,
          label: 'Cash Ledger',
        },
        {
          id: AcctPermission.CashBookDigitalReportCashInOutFlowView,
          label: 'Cash Inflow VS Outflow',
        },
        {
          id: AcctPermission.CashBookDigitalReportBankReconView,
          label: 'Bank Reconciliation Statement',
        },
      ],
    },
    {
      id: AcctPermission.CashBookGeneralSettingsView,
      label: 'General Settings',
      // children: [
      //   {
      //     id: AcctPermission.CashBookGeneralSettingsPolicySettingsView,
      //     label: 'Policy Settings',
      //     children: [
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsPolicySettingsCreate,
      //         label: 'Create',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.CashBookGeneralSettingsTaxSetupView,
      //     label: 'Tax Setup',
      //     children: [
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsTaxSetupCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsTaxSetupUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsTaxSetupDelete,
      //         label: 'Delete',
      //       },
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsTaxSetupInactive,
      //         label: 'Inactive',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.CashBookGeneralSettingsPaymentMethodView,
      //     label: 'Payment Method',
      //     children: [
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsPaymentMethodCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsPaymentMethodUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsPaymentMethodDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.CashBookGeneralSettingsCreditCardTypeView,
      //     label: 'Credit Card Type',
      //     children: [
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsCreditCardTypeCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsCreditCardTypeUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsCreditCardTypeDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.CashBookGeneralSettingsBankProfileView,
      //     label: 'Bank Profile',
      //     children: [
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsBankProfileCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsBankProfileUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.CashBookGeneralSettingsBankProfileDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      // ],
    },
    {
      id: AcctPermission.CashBookCompanySettingsView,
      label: 'Company Settings',
      // children: [
      //   {
      //     id: AcctPermission.CashBookCompanySettingsNumberingStructuresView,
      //     label: 'Document Numbering',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.CashBookCompanySettingsNumberingStructuresCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.CashBookCompanySettingsNumberingStructuresUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.CashBookCompanySettingsNumberingStructuresDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.CashBookCompanySettingsBankAccountView,
      //     label: 'Bank Account',
      //     children: [
      //       {
      //         id: AcctPermission.CashBookCompanySettingsBankAccountCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.CashBookCompanySettingsBankAccountUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.CashBookCompanySettingsBankAccountDelete,
      //         label: 'Delete',
      //       },
      //       {
      //         id: AcctPermission.CashBookCompanySettingsBankAccountInactive,
      //         label: 'Inactive',
      //       },
      //     ],
      //   },
      // ],
    },
  ],
}

export const accPayablePerm: RenderPermissionTree = {
  id: AcctPermission.AccPayableIcon,
  label: 'Accounts Payable',
  layer: '1',
  children: [
    {
      id: AcctPermission.AccPayableCreditorProfileView,
      label: 'Creditor Account',
      children: [
        {
          id: AcctPermission.AccPayableCreditorProfileUpdate,
          label: 'Update',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableAdvancesView,
      label: 'Advance',
      children: [
        {
          id: AcctPermission.AccPayableAdvancesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableAdvancesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableAdvancesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableAdvancesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableAdvancesAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccPayableAdvancesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableInvoicesView,
      label: 'Invoice',
      children: [
        {
          id: AcctPermission.AccPayableInvoicesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableInvoicesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableInvoicesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableInvoicesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableInvoicesDuplicate,
          label: 'Duplicate',
        },
        {
          id: AcctPermission.AccPayableInvoicesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableCreditNotesView,
      label: 'Credit Note',
      children: [
        {
          id: AcctPermission.AccPayableCreditNotesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableCreditNotesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableCreditNotesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableCreditNotesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableCreditNotesAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccPayableCreditNotesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableCreditorCreditNotesView,
      label: 'Credit Note from Creditor',
      children: [
        {
          id: AcctPermission.AccPayableCreditorCreditNotesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableCreditorCreditNotesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableCreditorCreditNotesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableCreditorCreditNotesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableCreditorCreditNotesAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccPayableCreditorCreditNotesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableDebitNotesView,
      label: 'Debit Note',
      children: [
        {
          id: AcctPermission.AccPayableDebitNotesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableDebitNotesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableDebitNotesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableDebitNotesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableDebitNotesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableCreditorDebitNotesView,
      label: 'Debit Note from Creditor',
      children: [
        {
          id: AcctPermission.AccPayableCreditorDebitNotesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableCreditorDebitNotesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableCreditorDebitNotesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableCreditorDebitNotesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableCreditorDebitNotesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayablePaymentView,
      label: 'Payment',
      children: [
        {
          id: AcctPermission.AccPayablePaymentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayablePaymentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayablePaymentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayablePaymentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayablePaymentAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccPayablePaymentApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableBatchPaymentView,
      label: 'Batch Payment',
      children: [
        {
          id: AcctPermission.AccPayableBatchPaymentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableBatchPaymentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableBatchPaymentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableBatchPaymentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableBatchPaymentPaid,
          label: 'Pay',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableRefundView,
      label: 'Refund',
      children: [
        {
          id: AcctPermission.AccPayableRefundCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableRefundUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableRefundDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableRefundDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableRefundApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableDigitalReportView,
      label: 'Digital Reports',
      children: [
        {
          id: AcctPermission.AccPayableDigitalReportAdvanceLedgerView,
          label: 'Advance Ledger',
        },
        {
          id: AcctPermission.AccPayableDigitalReportOverduePayableView,
          label: 'Overdue Payable',
        },
        {
          id: AcctPermission.AccPayableDigitalReportCreditorLedgerByDocDateView,
          label: 'Creditor Ledger Detail by Document Date',
        },
        {
          id:
            AcctPermission.AccPayableDigitalReportCreditorLedgerDetailTrxDateView,
          label: 'Creditor Ledger Detail by Transaction Date',
        },
        {
          id: AcctPermission.AccPayableDigitalReportCreditorAgingByDocDateView,
          label: 'Creditor Aging Detail by Document Date',
        },
        {
          id: AcctPermission.AccPayableDigitalReportCreditorAgingTrxDateView,
          label: 'Creditor Aging Detail by Transaction Date',
        },
        {
          id:
            AcctPermission.AccPayableDigitalReportCreditorAgingSummaryDocDateView,
          label: 'Creditor Aging Summary by Document Date',
        },
        {
          id:
            AcctPermission.AccPayableDigitalReportCreditorAgingSummaryTrxDateView,
          label: 'Creditor Aging Summary by Transaction Date',
        },
        {
          id: AcctPermission.AccPayableDigitalReportCreditorControlSummaryView,
          label: 'Creditor Control Summary',
        },
        {
          id: AcctPermission.AccPayableDigitalReportCreditorControlDetailView,
          label: 'Creditor Control Detail',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableGeneralSettingsView,
      label: 'General Settings',
      // children: [
      //   {
      //     id: AcctPermission.AccPayableGeneralSettingsCreditorTypeView,
      //     label: 'Creditor Type',
      //     children: [
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsCreditorTypeCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsCreditorTypeUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsCreditorTypeDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.AccPayableGeneralSettingsCreditorProfileView,
      //     label: 'Creditor Profile',
      //     children: [
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsCreditorProfileCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsCreditorProfileUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsCreditorProfileAssign,
      //         label: 'Assign',
      //       },
      //       {
      //         id:
      //           AcctPermission.AccPayableGeneralSettingsCreditorProfileInactive,
      //         label: 'Inactive',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.AccPayableGeneralSettingsExpenseClassView,
      //     label: 'Item Category',
      //     children: [
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsExpenseClassCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsExpenseClassUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsExpenseClassAssign,
      //         label: 'Assign',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsExpenseClassDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.AccPayableGeneralSettingsExpenseItemView,
      //     label: 'Item ',
      //     children: [
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsExpenseItemCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsExpenseItemUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsExpenseItemInactive,
      //         label: 'Inactive',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsExpenseItemDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.AccPayableGeneralSettingsUomSetupView,
      //     label: 'UOM Setup',
      //     children: [
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsUomSetupCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsUomSetupUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsUomSetupDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.AccPayableGeneralSettingsUomConversionView,
      //     label: 'UOM Conversion Setup',
      //     children: [
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsUomConversionCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsUomConversionUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.AccPayableGeneralSettingsUomConversionDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      // ],
    },
    {
      id: AcctPermission.AccPayableCompanySettingsView,
      label: 'Company Settings',
      // children: [
      //   {
      //     id: AcctPermission.AccPayableCompanySettingsNumberingStructuresView,
      //     label: 'Document Numbering',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.AccPayableCompanySettingsNumberingStructuresCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.AccPayableCompanySettingsNumberingStructuresUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.AccPayableCompanySettingsNumberingStructuresDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.AccPayableCompanySettingsAgingView,
      //     label: 'Aging',
      //     children: [
      //       {
      //         id: AcctPermission.AccPayableCompanySettingsAgingCreate,
      //         label: 'Create',
      //       },
      //     ],
      //   },
      // ],
    },
  ],
}

export const accReceivablePerm: RenderPermissionTree = {
  id: AcctPermission.AccReceivableIcon,
  label: 'Accounts Receivable',
  layer: '1',
  children: [
    {
      id: AcctPermission.AccReceivableDebtorAccView,
      label: 'Debtor Account',
      children: [
        {
          id: AcctPermission.AccReceivableDebtorAccCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableDebtorAccUpdate,
          label: 'Update',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableInvoicesView,
      label: 'Invoices',
      children: [
        {
          id: AcctPermission.AccReceivableInvoicesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableInvoicesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableInvoicesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableInvoicesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableInvoicesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableCreditNotesView,
      label: 'Credit Note',
      children: [
        {
          id: AcctPermission.AccReceivableCreditNotesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableCreditNotesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableCreditNotesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableCreditNotesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableCreditNotesAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccReceivableCreditNotesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableDebitNotesView,
      label: 'Debit Note',
      children: [
        {
          id: AcctPermission.AccReceivableDebitNotesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableDebitNotesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableDebitNotesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableDebitNotesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableDebitNotesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableAdvancesView,
      label: 'Advance',
      children: [
        {
          id: AcctPermission.AccReceivableAdvancesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableAdvancesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableAdvancesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableAdvancesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableAdvancesAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccReceivableAdvancesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableReceiptsView,
      label: 'Official Receipt',
      children: [
        {
          id: AcctPermission.AccReceivableReceiptsCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableReceiptsUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableReceiptsDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableReceiptsDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableReceiptsAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccReceivableReceiptsApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableRefundView,
      label: 'Refund',
      children: [
        {
          id: AcctPermission.AccReceivableRefundCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableRefundUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableRefundDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableRefundDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableRefundApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableDigitalReportsView,
      label: 'Digital Reports',
      children: [
        {
          id:
            AcctPermission.AccReceivableDigitalReportsDebtorLedgerByDocDateView,
          label: 'Debtor Ledger Detail By Doc Date',
        },
        {
          id: AcctPermission.AccReceivableDigitalReportsStatementAccountView,
          label: 'Statement of Account',
        },
        {
          id:
            AcctPermission.AccReceivableDigitalReportsDebtorAgingSummaryByTrxDateView,
          label: 'Debtor Aging Summary by Trx Date',
        },
        {
          id:
            AcctPermission.AccReceivableDigitalReportsDebtorAgingSummaryByDocDateView,
          label: 'Debtor Aging Summary by Document Date',
        },
        {
          id:
            AcctPermission.AccReceivableDigitalReportsDebtorAgingDetailByTrxDateView,
          label: 'Debtor Aging Detail by Trx Date',
        },
        {
          id:
            AcctPermission.AccReceivableDigitalReportsDebtorControlSummaryView,
          label: 'Debtor Control Summary',
        },
        {
          id: AcctPermission.AccReceivableDigitalReportsDebtorControlDetailView,
          label: 'Debtor Control Detail',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableGeneralSettingsView,
      label: 'General Settings',
      // children: [
      //   {
      //     id: AcctPermission.AccReceivableGeneralSettingsDebtorTypeView,
      //     label: 'Debtor Type',
      //     children: [
      //       {
      //         id: AcctPermission.AccReceivableGeneralSettingsDebtorTypeCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.AccReceivableGeneralSettingsDebtorTypeUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.AccReceivableGeneralSettingsDebtorTypeDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.AccReceivableGeneralSettingsBillItemView,
      //     label: 'Bill Item',
      //     children: [
      //       {
      //         id: AcctPermission.AccReceivableGeneralSettingsBillItemCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.AccReceivableGeneralSettingsBillItemUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.AccReceivableGeneralSettingsBillItemDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      // ],
    },
    {
      id: AcctPermission.AccReceivableCompanySettingsView,
      label: 'Company Settings',
      // children: [
      //   {
      //     id:
      //       AcctPermission.AccReceivableCompanySettingsNumberingStructuresView,
      //     label: 'Document Numbering',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.AccReceivableCompanySettingsNumberingStructuresCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.AccReceivableCompanySettingsNumberingStructuresUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.AccReceivableCompanySettingsNumberingStructuresDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.AccReceivableCompanySettingsAgingView,
      //     label: 'Aging',
      //     children: [
      //       {
      //         id: AcctPermission.AccReceivableCompanySettingsAgingCreate,
      //         label: 'Create',
      //       },
      //     ],
      //   },
      // ],
    },
  ],
}

export const inventoryControlPerm: RenderPermissionTree = {
  id: AcctPermission.InventoryControlIcon,
  label: 'Inventory Control',
  layer: '1',
  children: [
    {
      id: AcctPermission.InventoryControlStockEnquiryView,
      label: 'Stock Enquiry',
    },
    {
      id: AcctPermission.InventoryControlStockRequisitionView,
      label: 'Stock Requisition',
      children: [
        {
          id: AcctPermission.InventoryControlStockRequisitionCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockRequisitionUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockRequisitionDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockRequisitionDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockRequisitionApproveReject,
          label: 'Approve/Reject',
        },
        {
          id: AcctPermission.InventoryControlStockRequisitionCancel,
          label: 'Cancel',
        },
      ],
    },
    {
      id: AcctPermission.InventoryControlStockIssueView,
      label: 'Stock Issue',
      children: [
        {
          id: AcctPermission.InventoryControlStockIssueCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockIssueUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockIssueDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockIssueDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockIssueApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },

    {
      id: AcctPermission.InventoryControlStockReceiptView,
      label: 'Stock Receipt',
      children: [
        {
          id: AcctPermission.InventoryControlStockReceiptCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockReceiptUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockReceiptDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockReceiptDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockReceiptApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },

    {
      id: AcctPermission.InventoryControlStockTransferView,
      label: 'Stock Transfer',
      children: [
        {
          id: AcctPermission.InventoryControlStockTransferCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockTransferUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockTransferDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockTransferDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockTransferApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.InventoryControlStockAdjustmentView,
      label: 'Stock Qty Adjustment',
      children: [
        {
          id: AcctPermission.InventoryControlStockAdjustmentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockAdjustmentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockAdjustmentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockAdjustmentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockAdjustmentApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.InventoryControlStockCostAdjustmentView,
      label: 'Stock Cost Adjustment',
      children: [
        {
          id: AcctPermission.InventoryControlStockCostAdjustmentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockCostAdjustmentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockCostAdjustmentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockCostAdjustmentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockCostAdjustmentApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },

    {
      id: AcctPermission.InventoryControlCycleCountingView,
      label: 'Cycle Count',
      children: [
        {
          id: AcctPermission.InventoryControlCycleCountingCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlCycleCountingUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlCycleCountingDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlCycleCountingDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlCycleCountingApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },

    {
      id: AcctPermission.InventoryControlDigitalReportsView,
      label: 'Digital Reports',
      children: [
        {
          id:
            AcctPermission.InventoryControlDigitalReportsStockLedgerByLocationView,
          label: 'Stock Ledger',
        },
        {
          id:
            AcctPermission.InventoryControlDigitalReportsStockBalanceByDocDateView,
          label: 'Stock Balance by Document Date',
        },
        {
          id:
            AcctPermission.InventoryControlDigitalReportsStockBalanceByTrxDateView,
          label: 'Stock Balance by Transaction Date',
        },
      ],
    },
    {
      id: AcctPermission.InventoryControlGeneralSettingsView,
      label: 'General Settings',
      // children: [
      //   {
      //     id: AcctPermission.InventoryControlGeneralSettingsWarehouseView,
      //     label: 'Warehouse',
      //     children: [
      //       {
      //         id: AcctPermission.InventoryControlGeneralSettingsWarehouseCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.InventoryControlGeneralSettingsWarehouseUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.InventoryControlGeneralSettingsWarehouseDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.InventoryControlGeneralSettingsUomView,
      //     label: 'UOM Setup',
      //     children: [
      //       {
      //         id: AcctPermission.InventoryControlGeneralSettingsUomCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.InventoryControlGeneralSettingsUomUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.InventoryControlGeneralSettingsUomDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.InventoryControlGeneralSettingsUomExchangeView,
      //     label: 'UOM Conversion Setup',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsUomExchangeCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsUomExchangeUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsUomExchangeDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.InventoryControlGeneralSettingsStockCategoryView,
      //     label: 'Stock Category',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsStockCategoryCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsStockCategoryUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsStockCategoryDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.InventoryControlGeneralSettingsStockItemView,
      //     label: 'Stock Master File',
      //     children: [
      //       {
      //         id: AcctPermission.InventoryControlGeneralSettingsStockItemCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.InventoryControlGeneralSettingsStockItemUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsStockItemInactive,
      //         label: 'Inactive',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.InventoryControlGeneralSettingsTrxTypeView,
      //     label: 'Transaction Type',
      //     children: [
      //       {
      //         id: AcctPermission.InventoryControlGeneralSettingsTrxTypeCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.InventoryControlGeneralSettingsTrxTypeUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.InventoryControlGeneralSettingsTrxTypeInactive,
      //         label: 'Inactive',
      //       },
      //     ],
      //   },
      //   {
      //     id:
      //       AcctPermission.InventoryControlGeneralSettingsStockQtyAdjReasonsView,
      //     label: 'Stock Quantity Adjustment Reasons',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsStockQtyAdjReasonsCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsStockQtyAdjReasonsUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsStockQtyAdjReasonsDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id:
      //       AcctPermission.InventoryControlGeneralSettingsNumberingStructureView,
      //     label: 'Document Numbering',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsNumberingStructureCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsNumberingStructureUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.InventoryControlGeneralSettingsNumberingStructureDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      // ],
    },
  ],
}

export const generalPurchasePerm: RenderPermissionTree = {
  id: AcctPermission.GeneralPurchaseIcon,
  label: 'General Purchase',
  children: [
    {
      id: AcctPermission.GeneralPurchaseScheduledPurchasesView,
      label: 'Scheduled Purchase',
      children: [
        {
          id: AcctPermission.GeneralPurchaseScheduledPurchasesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseScheduledPurchasesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseScheduledPurchasesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseScheduledPurchasesPreparePo,
          label: 'Prepare PO',
        },
        {
          id: AcctPermission.GeneralPurchaseScheduledPurchasesGeneratePo,
          label: 'Generate PO',
        },
        {
          id: AcctPermission.GeneralPurchaseScheduledPurchasesPurchaseRequest,
          label: 'Purchase Request',
        },
        {
          id: AcctPermission.GeneralPurchaseScheduledPurchasesVerifyRequest,
          label: 'Verify Request',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchasePurchaseRequisitionView,
      label: 'Purchase Requisition',
      children: [
        {
          id: AcctPermission.GeneralPurchasePurchaseRequisitionCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchasePurchaseRequisitionUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchasePurchaseRequisitionDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchasePurchaseRequisitionApproveReject,
          label: 'Approve/Reject',
        },
        {
          id: AcctPermission.GeneralPurchasePurchaseRequisitionCancel,
          label: 'Cancel',
        },
        {
          id: AcctPermission.GeneralPurchasePurchaseRequisitionDuplicate,
          label: 'Duplicate',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseRequestForQuotationView,
      label: 'Request for Quotation',
      children: [
        {
          id: AcctPermission.GeneralPurchaseRequestForQuotationCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseRequestForQuotationUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseRequestForQuotationDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseRequestForQuotationApproveReject,
          label: 'Approve/Reject',
        },
        {
          id: AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesView,
          label: 'RFQ Invitees',
          children: [
            {
              id:
                AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesCreate,
              label: 'Create',
            },
            {
              id:
                AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesUninvite,
              label: 'Uninvite',
            },
            {
              id:
                AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqView,
              label: 'Quoted RFQ',
              children: [
                {
                  id:
                    AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqCreate,
                  label: 'Create',
                },
                {
                  id:
                    AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqUpdate,
                  label: 'Update',
                },
              ],
            },
          ],
        },
        {
          id:
            AcctPermission.GeneralPurchaseRequestForQuotationEvaluationSelectionView,
          label: 'Evaluation & Selection',
          children: [
            {
              id:
                AcctPermission.GeneralPurchaseRequestForQuotationEvaluationSelectionCreate,
              label: 'Create',
            },
          ],
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseGeneratePoFromPrView,
      label: 'Generate PO from PR',
      children: [
        {
          id: AcctPermission.GeneralPurchaseGeneratePoFromPrCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseGeneratePoFromPrUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseGeneratePoFromPrDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseGeneratePoFromPrApproveReject,
          label: 'Approve/Reject',
        },
        {
          id: AcctPermission.GeneralPurchaseGeneratePoFromPrCancel,
          label: 'Cancel',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseCentralisedPoView,
      label: 'Centralised PO',
      children: [
        {
          id: AcctPermission.GeneralPurchaseCentralisedPoCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseCentralisedPoUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseCentralisedPoDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseCentralisedPoApproveReject,
          label: 'Approve/Reject',
        },
        {
          id: AcctPermission.GeneralPurchaseCentralisedPoCancel,
          label: 'Cancel',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseDirectPoView,
      label: 'Direct PO',
      children: [
        {
          id: AcctPermission.GeneralPurchaseDirectPoCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseDirectPoUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseDirectPoDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseDirectPoApproveReject,
          label: 'Approve/Reject',
        },
        {
          id: AcctPermission.GeneralPurchaseDirectPoCancel,
          label: 'Cancel',
        },
        {
          id: AcctPermission.GeneralPurchaseDirectPoDuplicate,
          label: 'Duplicate',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseGoodReceiveNoteView,
      label: 'Goods Receive Note',
      children: [
        {
          id: AcctPermission.GeneralPurchaseGoodReceiveNoteCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReceiveNoteUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReceiveNoteDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReceiveNoteApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseGoodReturnNoteView,
      label: 'Goods Return Note',
      children: [
        {
          id: AcctPermission.GeneralPurchaseGoodReturnNoteCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReturnNoteUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReturnNoteDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReturnNoteApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseDigitalReportsView,
      label: 'Digital Reports',
      children: [
        // {
        //   id:
        //     AcctPermission.GeneralPurchaseDigitalReportsPurchaseOrder_4WaysMatchReportView,
        //   label: 'Purchase Order 4 Ways Match Report',
        // },
        {
          id: AcctPermission.GeneralPurchaseDigitalReportsPoOutstandingView,
          label: 'PO Outstanding Report',
        },
        // {
        //   id:
        //     AcctPermission.GeneralPurchaseDigitalReportsPurchaseByItemCategoryView,
        //   label: 'Purchases By Item Category',
        // },
        // {
        //   id:
        //     AcctPermission.GeneralPurchaseDigitalReportsPurchaseAnalysisByItemView,
        //   label: 'Purchases Analysis By Item',
        // },
        {
          id:
            AcctPermission.GeneralPurchaseDigitalReportsGrnGrtnDailyReportView,
          label: 'GRN/GRTN Daily Report',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseGeneralSettingsView,
      label: 'General Settings',

      // children: [
      //   {
      //     id: AcctPermission.GeneralPurchaseGeneralSettingsSupplierCategoryView,
      //     label: 'Supplier Category',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsSupplierCategoryCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsSupplierCategoryUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsSupplierCategoryDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.GeneralPurchaseGeneralSettingsSupplierProfileView,
      //     label: 'Supplier Profile',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsSupplierProfileCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsSupplierProfileUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsSupplierProfileAssign,
      //         label: 'Assign',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsSupplierProfileInactive,
      //         label: 'Inactive',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.GeneralPurchaseGeneralSettingsUomSetupView,
      //     label: 'UOM Setup',
      //     children: [
      //       {
      //         id: AcctPermission.GeneralPurchaseGeneralSettingsUomSetupCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id: AcctPermission.GeneralPurchaseGeneralSettingsUomSetupUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id: AcctPermission.GeneralPurchaseGeneralSettingsUomSetupDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.GeneralPurchaseGeneralSettingsUomConversionView,
      //     label: 'UOM Conversion Setup',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsUomConversionCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsUomConversionUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsUomConversionDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id:
      //       AcctPermission.GeneralPurchaseGeneralSettingsPurchaseItemCategoryView,
      //     label: 'Purchase Item Category',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsPurchaseItemCategoryCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsPurchaseItemCategoryUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsPurchaseItemCategoryAssign,
      //         label: 'Assign',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsPurchaseItemCategoryDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.GeneralPurchaseGeneralSettingsPurchaseItemView,
      //     label: 'Purchase Item',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsPurchaseItemCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsPurchaseItemUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsPurchaseItemInactive,
      //         label: 'Inactive',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsPurchaseItemDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id:
      //       AcctPermission.GeneralPurchaseGeneralSettingsBulkPurchasePricingView,
      //     label: 'Bulk Purchase Pricing',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsBulkPurchasePricingCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsBulkPurchasePricingUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsBulkPurchasePricingDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id:
      //       AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyView,
      //     label: 'Standard Approval Policy',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionView,
      //         label: 'Purchase Requisition (PR)',
      //         children: [
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionCreate,
      //             label: 'Create',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionUpdate,
      //             label: 'Update',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionDelete,
      //             label: 'Delete',
      //           },
      //         ],
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqView,
      //         label: 'Request for Quotation (RFQ)',
      //         children: [
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqCreate,
      //             label: 'Create',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqUpdate,
      //             label: 'Update',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqDelete,
      //             label: 'Delete',
      //           },
      //         ],
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderView,
      //         label: 'Purchase Order (PO)',
      //         children: [
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderCreate,
      //             label: 'Create',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderUpdate,
      //             label: 'Update',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderDelete,
      //             label: 'Delete',
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     id:
      //       AcctPermission.GeneralPurchaseGeneralSettingsGeneralPurchasePolicyView,
      //     label: 'General Purchase Policy',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseGeneralSettingsGeneralPurchasePolicyCreate,
      //         label: 'Create',
      //       },
      //     ],
      //   },
      // ],
    },
    {
      id: AcctPermission.GeneralPurchaseCompanySettingsView,
      label: 'Company Settings',
      // children: [
      //   {
      //     id: AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyView,
      //     label: 'Approval Policy',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyPrView,
      //         label: 'Purchase Requisition (PR)',
      //         children: [
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyPrCreate,
      //             label: 'Create',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyPrUpdate,
      //             label: 'Update',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyPrDelete,
      //             label: 'Delete',
      //           },
      //         ],
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyRfqView,
      //         label: 'Request for Quotation (RFQ)',
      //         children: [
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyRfqCreate,
      //             label: 'Create',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyRfqUpdate,
      //             label: 'Update',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyRfqDelete,
      //             label: 'Delete',
      //           },
      //         ],
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyPoView,
      //         label: 'Purchase Order (PO)',
      //         children: [
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyPoCreate,
      //             label: 'Create',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyPoUpdate,
      //             label: 'Update',
      //           },
      //           {
      //             id:
      //               AcctPermission.GeneralPurchaseCompanySettingsApprovalPolicyPoDelete,
      //             label: 'Delete',
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     id:
      //       AcctPermission.GeneralPurchaseCompanySettingsNumberingStructuresView,
      //     label: 'Document Numbering',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseCompanySettingsNumberingStructuresCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseCompanySettingsNumberingStructuresUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseCompanySettingsNumberingStructuresDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      //   {
      //     id: AcctPermission.GeneralPurchaseCompanySettingsDeliveryLocationView,
      //     label: 'Delivery Location',
      //     children: [
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseCompanySettingsDeliveryLocationCreate,
      //         label: 'Create',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseCompanySettingsDeliveryLocationUpdate,
      //         label: 'Update',
      //       },
      //       {
      //         id:
      //           AcctPermission.GeneralPurchaseCompanySettingsDeliveryLocationDelete,
      //         label: 'Delete',
      //       },
      //     ],
      //   },
      // ],
    },
  ],
}
