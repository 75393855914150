import theme from '@ifca-root/react-component/src/assets/theme'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import { useMediaQuery } from '@material-ui/core'
import { SwapVert, Tune } from '@material-ui/icons'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import {
  useGetBankAccountQuery,
  useGetPaymentbyStatusListingLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { PaymentEntryList } from './PaymentList/PaymentEntryList'
import { PaymentPostingList } from './PaymentList/PaymentPostingList'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { SortDialog } from '@ifca-root/react-component/src/components/Sort/SortDialog'

export const PaymentMain = (props: any) => {
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID, BankAccountID }: any = useParams()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    loadPayment,
    {
      loading: PaymentbyStatusLoading,
      data: { getPaymentbyStatusListing } = {
        getPaymentbyStatusListing: [],
      },
      fetchMore,
    },
  ] = useGetPaymentbyStatusListingLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ getPaymentbyStatusListing }) => {
      if (getPaymentbyStatusListing?.length > 0) {
        setOriginalListing([...filteredList, ...getPaymentbyStatusListing])
      }
    },
  })

  useEffect(() => {
    if (listStatus) {
      setOriginalListing([])
      loadPayment({
        variables: {
          CompanyID: CompanyID,
          BankAccountID: BankAccountID,
          StatusArr: [listStatus],
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name !== '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
          sortField: selectedField === null ? undefined : selectedField,
          sortOrder: sortOrder === null ? undefined : sortOrder,
        },
      })
    }
  }, [listStatus])

  const {
    loading: BankAccountLoading,
    error: BankAccountError,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      BankAccountID: BankAccountID,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  /* -------------------------------------------- */
  /*                   EXIT CONF                  */
  /* -------------------------------------------- */

  const hasChanges = () => {
    if (postIDs?.size === 0) return false
    else return true
  }

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Doc Date',
      key: 'DocDate',
    },
  })

  //to make delay for search function for the query to load
  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadPayment({
          variables: {
            CompanyID: CompanyID,
            BankAccountID: BankAccountID,
            StatusArr: [listStatus],
            skip: 0,
            take: 30,
            searchValue: change,
            sortField: selectedField === null ? undefined : selectedField,
            sortOrder: sortOrder === null ? undefined : sortOrder,
          },
        })
      }, 1000)
    )
  }

  //*******************************************************//
  //-------------------SORTING DATA------------------------//
  //*******************************************************//

  const sortPVDataDialog = [
    { ID: '1', value: 'DocNo', Name: 'Document No' },
    { ID: '2', value: 'RefNo', Name: 'Reference No' },
    { ID: '3', value: 'DocDate', Name: 'Document Date' },
    { ID: '4', value: 'Description', Name: 'Description' },
    { ID: '5', value: 'DocAmt', Name: 'Amount' },
  ]

  const [openSortDialog, setOpenSortDialog] = useState(false)
  const [selectedField, setSelectedField] = useState('')
  const [sortOrder, setSortOrder] = useState(null)

  const handleClickSort = () => {
    setOpenSortDialog(true)
  }

  const handleListStatusChange = newStatus => {
    setListStatus(newStatus)
    setSelectedField(null)
    setSortOrder(null)
  }

  const handleSortConfirm = sortOption => {
    const { selectedField, sortOrder } = sortOption
    setOriginalListing([])
    loadPayment({
      variables: {
        CompanyID: CompanyID,
        BankAccountID: BankAccountID,
        StatusArr: [listStatus],
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name === '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
        sortField: selectedField,
        sortOrder: sortOrder,
      },
    })
  }

  return (
    <>
      {UserLoading && <Loading />}
      {BankAccountLoading && <Loading />}
      {PaymentbyStatusLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true && listStatus === 'SUBMIT'
            ? setOpenExitConf(true)
            : history.push(`/cash-book/${CompanyID}/submenu/${BankAccountID}`)
          localStorage.removeItem(`searchFilter`)
        }} //<- back button action
        smTitle="Cash Book"
        title={user?.companyName}
        routeSegments={[
          { name: 'Home Payment' },
          { name: 'Payment', current: true },
        ]}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getBankAccount[0]?.BankProfile?.Name}
            </span>
          </>
        }
        infoLine={
          <>
            <span className="xxTitle">{'Account No.'}&nbsp;:&nbsp;</span>
            <span className="xxTitle">{getBankAccount[0]?.AccountNo}</span>
          </>
        }
      />

      <div className="search-filter with-dropdown-filter with-subheader">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            changeDelay(e?.target?.value)
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            loadPayment({
              variables: {
                CompanyID: CompanyID,
                BankAccountID: BankAccountID,
                StatusArr: [listStatus],
                take: 30,
                skip: 0,
                searchValue:
                  getSearch?.name !== '' || getSearch?.name === undefined
                    ? undefined
                    : getSearch?.name,
                sortField: selectedField === null ? undefined : selectedField,
                sortOrder: sortOrder === null ? undefined : sortOrder,
              },
            })
          }}
          isDefaultValue={!!getSearch}
          option={{
            icon: <Tune />,
            onClick: openFilterDialog,
          }}
          sort={{
            icon: <SwapVert />,
            onClick: handleClickSort,
          }}
        />

        <SortDialog
          openSortDialog={openSortDialog}
          setOpenSortDialog={setOpenSortDialog}
          title="Sort Options"
          fieldList={sortPVDataDialog}
          onSortConfirm={handleSortConfirm}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
        {filterDialog}

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={handleListStatusChange}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && (
        <PaymentEntryList
          filteredList={filteredList}
          handleFilterDate={handleFilterDate}
          listLoading={PaymentbyStatusLoading}
          listStatus={listStatus}
          userList={getUsersByAccountAndSoftware}
          fetchMore={loadPayment}
          bankList={getBankAccount}
          setOriginalListing={setOriginalListing}
          selectedField={selectedField}
          sortOrder={sortOrder}
        />
      )}
      {listStatus === 'SUBMIT' && (
        // !PaymentbyStatusLoading && (
        <PaymentPostingList
          filteredList={filteredList}
          handleFilterDate={handleFilterDate}
          listLoading={PaymentbyStatusLoading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing}
          // loadData={loadData}
          fetchMore={loadPayment}
          selectedField={selectedField}
          sortOrder={sortOrder}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/cash-book/${CompanyID}/submenu/${BankAccountID}`)
        }}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
