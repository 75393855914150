import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { FileUploadInput as DocUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Grid,
  InputAdornment,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { changeDateFormat } from '@ifca-root/react-component/src/helpers/Functions/dateFunction'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import BigNumber from 'bignumber.js'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { DropdownTextfield } from 'containers/ARAPModule/Components/DropdownTextField'
import {
  getTaxRate,
  getTotalAmt,
} from 'containers/ARAPModule/Helper/AmountCalculation'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  DocumentListingDocument,
  GetArAdvanceDocument,
  RecordStatus,
  useCreateArAdvanceMutation,
  useDocumentListingLazyQuery,
  useGetBankAccountQuery,
  useGetCreditCardTypeQuery,
  useGetDebtorAccountQuery,
  useGetDocNumTitleQuery,
  useGetPaymentMethodQuery,
  useGetTaxSchemeQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateArAdvanceMutation,
} from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  formatDashDate,
  getDatePlusDay,
} from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
import '../ARAdvance.scss'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
interface ARAdvanceProps {
  TrxDate: string
  CompanyID: string
  DebtorAccountID: string
  AdvanceID: string
  DocDate: Date
  DocAmt: number
  RefNo: string
  Description: string
  Remark: string
  Attachment: null
  debitAmt: number
  approval: string
  user: string
  BankAccountID: string
  BankName: string
  PaymentMethodID: string
  CreditCardID: string
  ChequeNo: string
  ChequeDate: Date
  Amount: number
  TaxSchemeID: string
  TaxRate: number
  TaxAmt: number
  TaxInvoiceNo: string
  uploadAttach: string
}
interface FavARAdvanceProps {
  Name: string
}

export const ARAdvanceForm = (props: any) => {
  const { formMode, type } = props

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any
  const JournalPItem = editData?.JournalProcessingItem
  const { CompanyID, AdvanceID }: any = useParams()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [PaymentValidation, setPaymentValidation] = useState(false)
  const [valuepicked, setValuePicked] = useState<String>(null)
  const [voiceRemark, setVoiceRemark] = useState('')
  // Change Tax descrition into code number
  const [selectedTax, setSelectedTax] = useState(null)
  const [initDocs, setInitDocs] = useState([])

  const [TaxRate, setTaxRate] = useState(0)
  const [cost, setCost] = useState(amtNumStr(editData?.Amount) ?? 0)
  const [taxPerc, setTaxPerc] = useState(0)
  const [totalAmt, setTotalAmt] = useState(0.0)
  const [amount, setAmount] = useState(0.0)
  const [selectedTaxAmount, setSelectedTaxAmount] = useState(null)
  const [taxAmt, setTaxAmt] = useState(!!editData ? editData?.TaxAmt : 0)
  const [record, setRecord] = useState(false)
  const [singleRemark, setSingleRemark] = useState(null)
  const [audioInput, setAudioInput] = useState(editData?.Remark ?? '')
  const [text, setText] = useState(editData?.Remark ?? '')
  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  //Creditor Account Company Assignment//
  const {
    loading: DebtorAccountLoading,
    error: DebtorAccountError,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  //PaymentMethod
  const {
    loading: PaymentMethodLoading,
    error: PaymentMethodError,
    data: { getPaymentMethod } = { getPaymentMethod: [] },
  } = useGetPaymentMethodQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    called: bankAccountCalled,
    loading: bankAccountLoading,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RecordStatus: RecordStatus?.Active },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = { getDocumentNumberHeader: [] },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RefTable: 'AR_Advance' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  //CreditCardType
  const {
    loading: CreditCardTypeLoading,
    error: CreditCardTypeError,
    data: { getCreditCardType } = { getCreditCardType: [] },
  } = useGetCreditCardTypeQuery({
    fetchPolicy: 'network-only',
    variables: {},
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  // Tax
  const {
    loading: TaxLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    onCompleted: () => {},
    variables: { RecordStatus: RecordStatus.Active },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const ARAdvanceFormSchema = yup.object().shape({
    DocDate: yup.string().required('Doc Date is Required'),
    TrxDate: yup.string().required('Transaction Date is Required'),
    RefNo: CommonYupValidation.requireField(SystemMsgs.referenceNo()),
    DebtorAccountID: yup.string().required('DebtorAccountID is Required'),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    // AdvanceID: CommonYupValidation.requireField(SystemMsgs.journalType()),
    PaymentMethodID: CommonYupValidation.requireField(
      'Payment Method is required'
    ),
    CreditCardID:
      PaymentValidation && valuepicked?.includes('Card')
        ? CommonYupValidation.requireField('Credit Card Type is required')
        : null,
    ChequeNo: yup.string().when('PaymentMethodID', {
      is: getPaymentMethod?.find(method => method?.Name === 'Cheque')
        ?.PaymentMethodID,
      then: yup.string().required('Cheque no. is required'),
      otherwise: null,
    }),
    ChequeDate: yup.date().when('PaymentMethodID', {
      is: getPaymentMethod?.find(method => method?.Name === 'Cheque')
        ?.PaymentMethodID,
      then: yup.date().required('Cheque date is required'),
      otherwise: null,
    }),

    BankAccountID: CommonYupValidation.requireField('Bank Account is required'),
    TaxSchemeID: CommonYupValidation.requireField('Tax Code is required'),
    Amount: yup
      .number()
      .required('Amount is required')
      .moreThan(0)
      .typeError('Amount is required'),
    // TaxRate: yup.string().required('Tax Rate is Required'),
  })

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
    clearErrors,
    reset,
    formState: { isSubmitted },
  } = useForm<ARAdvanceProps>({
    defaultValues: {
      CompanyID: !!editData ? editData?.CompanyID : '',
      DocDate: !!editData ? editData?.DocDate : new Date(),
      TrxDate: editData ? editData?.TransactionDate : new Date(),
      RefNo: !!editData ? editData?.RefNo : '',
      DebtorAccountID: !!editData ? editData?.CreditAccountID : '',
      BankAccountID: !!editData ? editData?.BankAccountID : '',
      PaymentMethodID: !!editData ? editData?.PaymentMethodID : '',
      CreditCardID: !!editData ? editData?.CreditCardID : '',
      ChequeNo: !!editData ? editData?.ChequeNo : '',
      ChequeDate: !!editData ? editData?.ChequeDate : new Date(),
      TaxSchemeID: !!editData ? editData?.TaxSchemeID : '',
      TaxRate: !!editData ? editData?.TaxRate : '',
      TaxInvoiceNo: !!editData ? editData?.TaxInvoiceNo : '',
      TaxAmt: !!editData ? editData?.TaxAmt : 0,
      DocAmt: !!editData ? editData?.DocAmt : 0,
      Amount: !!editData ? editData?.Amount : 0,
      Description: !!editData ? editData?.Description : '',
      Remark: !!editData ? editData?.Remark : '',
      Attachment: !!editData ? editData?.Attachment : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(ARAdvanceFormSchema),
  })

  const [selectedBank, setSelectedBank] = useState(null)
  // select bank
  const handleBankChange = event => {
    setSelectedBank(
      getBankAccount.find(x => x?.BankAccountID === event?.target?.value)
    )
  }

  //Changing PaymentMethodID
  const [selectedPM, setSelectedPM] = useState(null)
  const paymentmethod: any = getPaymentMethod

  const handlePMChange = event => {
    if (
      getPaymentMethod?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name === 'Cheque' ||
      getPaymentMethod
        ?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name?.includes('Card')
    ) {
      setPaymentValidation(true)
    } else setPaymentValidation(false)
    if (
      getPaymentMethod?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name === 'Cheque'
    ) {
      setValuePicked('Cheque')
    } else if (
      getPaymentMethod
        ?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name?.includes('Card')
    ) {
      setValuePicked('Card')
    } else setValuePicked('')
  }

  //Changing If PM chosed CreditCard
  const [selectedCC, setSelectedCC] = useState(null)
  const creditcard: any = getCreditCardType

  const handleCCChange = event => {
    setSelectedCC(
      creditcard.find(x => x?.CreditCardID === event?.target?.value)
    )
  }

  const taxScheme: any = getTaxScheme
  // change tax
  const handleTaxChange = (event, taxEffectiveDate) => {
    setSelectedTax(taxScheme.find(x => x?.TaxSchemeID === event?.target?.value))
    setTaxRate(
      taxScheme.filter(x => x?.TaxSchemeID === event?.target?.value)[0]
        .LatestTax === null
        ? taxScheme
            .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
            ?.TaxEffective.reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
        : taxScheme
            .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
            ?.TaxEffective?.filter(
              x => new Date(x?.Date) <= new Date(taxEffectiveDate)
            )
            .reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
    )

    setTaxAmt(
      new BigNumber(
        taxScheme.filter(x => x?.TaxSchemeID === event?.target?.value)[0]
          .LatestTax === null
          ? taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective.reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
          : taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective?.filter(
                x => new Date(x?.Date) <= new Date(taxEffectiveDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
      )
        .dividedBy(100)
        .multipliedBy(cost)
        .toNumber()
    )
  }

  const handleTaxRateChange = event => {
    setTaxRate(event.value)
    new BigNumber(selectedTax?.LatestTax?.Rate)
  }

  // Function//
  const handleAmtChange = event => {
    setCost(event)

    setTaxAmt(
      new BigNumber(
        selectedTax?.LatestTax === null
          ? selectedTax?.TaxEffective.reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
          : selectedTax?.TaxEffective?.filter(
              x => new Date(x?.Date) <= new Date(taxEffectiveDate)
            ).reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
      )
        .dividedBy(100)
        .multipliedBy(event)
        .toNumber()
    )
  }

  const handleTaxAmtChange = event => {
    setTaxAmt(event.value)
  }

  const calculateTotalAmt = () => {
    setTotalAmt(amount + amount * (TaxRate / 100))
  }

  // change tax amount(controller) *taxAmtChangeableNeeded only
  useEffect(() => {
    if (!isNaN(taxAmt)) {
      setValue('TaxAmt', taxAmt)
      clearErrors('TaxAmt')
    }
  }, [taxAmt])

  // Autoset tax after select tax amount
  useEffect(() => {
    if (!!selectedTax) {
      setValue('TaxSchemeID', selectedTax?.TaxSchemeID)
      clearErrors('TaxSchemeID')
    }
  }, [selectedTax])

  useEffect(() => {
    calculateTotalAmt()
  }, [amount, TaxRate])

  // To get list of Tax Code that has been effective //
  const taxEffectiveDate: any = new Date().toISOString().slice(0, 10)

  const taxSchemeInput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'INPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  const taxSchemeOutput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'OUTPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  useEffect(() => {
    if (!!editData && !!taxScheme) {
      // set tax on edit
      setSelectedTax(
        taxScheme?.find(x => x?.TaxSchemeID === editData?.TaxSchemeID)
      )
      setTaxRate(
        taxScheme
          .filter(x => x?.TaxSchemeID === editData?.TaxSchemeID)[0]
          ?.TaxEffective?.filter(
            x => new Date(x?.Date) <= new Date(taxEffectiveDate)
          )
          .reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          })?.Rate
      )
    }
  }, [editData, taxScheme])

  // Edit Bank Account
  useEffect(() => {
    if (!!editData && !!getBankAccount) {
      setSelectedBank(
        getBankAccount?.find(e => e?.BankAccountID === editData?.BankAccountID)
      )
    }
  }, [editData, getBankAccount])

  //////////////////////////////////////
  //...........ATTACHMENTS.............//
  ///////////////////////////////////////
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    loadDoc,
    { loading: DocLoad, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  useEffect(() => {
    if (!DocLoad && formMode !== 'add') {
      loadDoc({ variables: { refID: AdvanceID } })
    }
    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [formMode, AdvanceID, editData])

  const [
    createARAdvance,
    {
      loading: createARAdvanceLoading,
      called: createARAdvanceCalled,
      error: createARAdvanceError,
    },
  ] = useCreateArAdvanceMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/advance`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const [
    updateARAdvance,
    {
      loading: updateARAdvanceLoading,
      called: updateARAdvanceCalled,
      error: updateARAdvanceError,
    },
  ] = useUpdateArAdvanceMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      mode === 'approve-reject'
        ? setTimeout(() => {
            history.push({
              pathname: `/account-receivable/${CompanyID}/advance`,
              state: { success: true, msgMode: 'update' },
            })
          }, 500)
        : setTimeout(() => {
            history.push({
              pathname: `/account-receivable/${CompanyID}/advance`,
              state: { success: true, msgMode: 'update' },
            })
          }, 500)
    },
  })

  const [val, setVal] = useState(false)
  const [errorDia, setErrorDia] = useState<boolean>(false)

  const onSubmit = (data, status) => {
    if (val == false) {
      setVal(true)
      if (formMode === 'add') {
        // if (
        //   getDocumentNumberHeader?.filter(x => x?.RefTable === 'AR_Advance')?.length === 0
        // ) {
        //   setOpenSnackBar(true)
        //   setSnackBarMsg(SystemMsgs.errorNumberingStructure())
        // } else {
        createARAdvance({
          variables: {
            input: {
              CompanyID: CompanyID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              RefNo: data?.RefNo,
              DebtorAccountID: data?.DebtorAccountID,
              PaymentMethodID: data?.PaymentMethodID,
              CreditCardID: data?.CreditCardID,
              BankAccountID: data?.BankAccountID,
              Description: data?.Description,
              Remark: data?.Remark,
              Attachment: files,
              ChequeNo: data?.ChequeNo,
              ChequeDate: data?.ChequeDate,
              ChequeExpiryDate: !!data?.ChequeDate
                ? changeDateFormat(
                    getDatePlusDay(data?.ChequeDate, 180),
                    'DD MMM YYYY'
                  )
                : null,
              ApprovalStatus: statusInput(status),
              //TAX
              TaxSchemeID: data?.TaxSchemeID,
              Amount: parseFloat(amtNumStr(data?.Amount)),
              DocAmtBeforeTax: parseFloat(amtNumStr(data?.Amount)),
              TaxRate: getTaxRate(TaxRate),
              TaxAmt: parseFloat(data?.TaxAmt),
              DocAmt: getTotalAmt(true, cost, taxAmt, TaxRate),
              TaxInvoiceNo: data?.TaxInvoiceNo,
              // BalanceAmt: getTotalAmt(true, cost, taxAmt, TaxRate),
            },
          },
          refetchQueries: [
            {
              query: GetArAdvanceDocument,
            },
            {
              query: DocumentListingDocument,
            },
          ],
        })
        // }
      } else if (formMode !== 'add') {
        updateARAdvance({
          variables: {
            input: {
              CompanyID: CompanyID,
              AdvanceID: AdvanceID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              RefNo: data?.RefNo,
              DebtorAccountID: data?.DebtorAccountID,
              PaymentMethodID: data?.PaymentMethodID,
              CreditCardID: data?.CreditCardID,
              BankAccountID: data?.BankAccountID,
              Description: data?.Description,
              Remark: data?.Remark,
              Attachment: files,
              ChequeNo: data?.ChequeNo,
              ChequeDate: data?.ChequeDate,
              ChequeExpiryDate: !!data?.ChequeDate
                ? changeDateFormat(
                    getDatePlusDay(data?.ChequeDate, 180),
                    'DD MMM YYYY'
                  )
                : null,
              ApprovalStatus: statusInput(status),
              //TAX
              TaxSchemeID: data?.TaxSchemeID,
              Amount: parseFloat(amtNumStr(data?.Amount)),
              DocAmtBeforeTax: parseFloat(amtNumStr(data?.Amount)),
              TaxRate: getTaxRate(TaxRate),
              TaxAmt: parseFloat(data?.TaxAmt),
              DocAmt: getTotalAmt(true, cost, taxAmt, TaxRate),
              TaxInvoiceNo: data?.TaxInvoiceNo,
              // BalanceAmt: getTotalAmt(true, cost, taxAmt, TaxRate),
            },
          },
          refetchQueries: [
            {
              query: GetArAdvanceDocument,
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: AdvanceID,
              },
            },
          ],
        })
      }
    }
  }

  let statusInput = mode => {
    let temp
    switch (mode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Approved
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  const createUpdateLoading = editData
    ? updateARAdvanceLoading
    : createARAdvanceLoading

  const createUpdateCalled = editData
    ? updateARAdvanceCalled
    : createARAdvanceCalled

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const trxDateTimestamp = new Date(watch('TrxDate'))
  const yearTrxDate = trxDateTimestamp.getFullYear()
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`

  const openPeriod1 =
    transferDate <= latestOpenPeriodCheckingDate?.StartDate ||
    transferDate >= latestOpenPeriodCheckingDate?.EndDate

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? true : false
  const checkingYearClose2 = openPeriod2 ? false : true

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },

    disabled:
      checkingYearClose1 || checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableAdvancesDraft,
          }),
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },

    disabled:
      !!errors?.DocDate ||
      !!errors?.TrxDate ||
      !!errors?.RefNo ||
      !!errors?.DebtorAccountID ||
      !!errors?.PaymentMethodID ||
      !!errors?.Amount ||
      !!errors?.TaxSchemeID ||
      !!errors?.BankAccountID ||
      !!errors?.Description ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableAdvancesUpdate,
          }),
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: e => {
      handleSubmit(data =>
        getDocumentNumberHeader?.filter(x => x?.RefTable === 'AR_Advance')
          ?.length === 0
          ? (setOpenSnackBar(true),
            setSnackBarMsg(SystemMsgs.errorNumberingStructure()))
          : !createUpdateCalled && onSubmit(data, 'submit')
      )()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled: checkingYearClose1 || checkingYearClose2 ? true : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  const [openExitConf, setOpenExitConf] = useState(null)

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
      formMode: formMode,
    })

  return (
    <>
      {TaxLoading && <Loading />}
      {bankAccountLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {PaymentMethodLoading && <Loading />}
      {CreditCardTypeLoading && <Loading />}
      {createARAdvanceLoading && <Loading />}
      {updateARAdvanceLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          hasChanges() === true
            ? setOpenExitConf(true)
            : history.push(`/account-receivable/${CompanyID}/advance`)
          localStorage.removeItem('DebitAmt')
        }}
        smTitle={'Accounts Receivable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Accounts Payable' },
          { name: 'AP Submenu' },
          { name: 'Advance Listing' },
          { name: 'Advance', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              mode === 'add'
                ? 'New'
                : mode === 'approve-reject'
                ? 'Approve/Reject'
                : 'Edit',
            current: true,
          },
        ]}
      />

      {!DebtorAccountLoading && (
        <ContentWrapper float footer>
          <CardContents>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                required
                as={KeyboardDatePicker}
                name="DocDate"
                label="Date"
                control={control}
                onChange={(date: Date | null) => {
                  //date
                }}
                // onAccept={(date: Date | null) => {
                //   updateNewPaymentItem(date, taxEffectiveList)
                // }}
                format="dd/MM/yyyy"
                value={watch(
                  formMode === 'add' ? new Date() : editData?.DocDate
                )}
                margin="dense"
                allowKeyboardControl
                ref={register}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                defaultValue={editData ? editData?.DocDate : new Date()}
                showTodayButton
                className="left"
                disabled={mode == 'approve-reject'}
                // minDate={
                //   //   DocDateValidationBefore(
                //   // getDocumentDateValidation[0]?.MonthsBefore
                //   //)
                //   formMode === 'add'
                //     ? DocDateValidationBefore(
                //         getDocumentDateValidation[0]?.MonthsBefore
                //       )
                //     : editData?.DocDate
                // }
                // maxDate={DocDateValidationAfter(
                //   getDocumentDateValidation[0]?.MonthsAfter
                // )}
                helperText={
                  checkingYearClose1
                    ? 'Financial Period already closed'
                    : errors?.DocDate?.message
                }
                error={errors?.DocDate || checkingYearClose1 ? true : false}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="TrxDate"
                required
                label="Transaction Date"
                control={control}
                format="dd/MM/yyyy"
                margin="dense"
                allowKeyboardControl
                onChange={(date: Date | null) => {}}
                ref={register}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                showTodayButton
                className="right"
                value={new Date()}
                defaultValue={editData ? editData?.TransactionDate : new Date()}
                helperText={
                  checkingYearClose2
                    ? 'Financial Period already closed'
                    : errors?.TrxDate?.message
                }
                error={errors?.TrxDate || checkingYearClose2 ? true : false}
                minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
                maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              />
            </MuiPickersUtilsProvider>

            <Controller
              as={TextField}
              id="standard-basic"
              name="RefNo"
              label="Reference No."
              required
              autoComplete="off"
              control={control}
              // className="right"
              margin="dense"
              ref={register}
              helperText={errors?.RefNo?.message}
              error={errors?.RefNo ? true : false}
              defaultValue={editData ? editData?.RefNo : ''}
              disabled={mode == 'approve-reject'}
            />

            <Controller
              render={({ value, onChange }) => {
                const defVal = getDebtorAccount?.filter(
                  x => x?.DebtorAccountID === editData?.DebtorAccountID
                )[0]
                return (
                  <Autocomplete
                    options={
                      getDebtorAccount?.sort((a, b) => {
                        return a?.DebtorName?.localeCompare(b?.DebtorName)
                      }) || []
                    }
                    getOptionLabel={option => `${option?.DebtorName}`}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('DebtorAccountID', newValue?.DebtorAccountID)
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.DebtorName}</span>
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.DebtorAccountID?.message}
                            error={errors?.DebtorAccountID ? true : false}
                            label="Debtor Name"
                            style={{ width: '100%' }}
                            margin="dense"
                            required
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Debtor Name"
              name="DebtorAccountID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="dense"
              ref={register}
              helperText={errors?.DebtorAccountID?.message}
              error={errors?.DebtorAccountID ? true : false}
              defaultValue={editData?.DebtorAccountID}
              required
              disabled={mode === 'approve-reject'}
            />

            <Controller
              render={({ onChange, value }) => (
                <TextField
                  label="Payment Method"
                  required
                  margin="dense"
                  select
                  value={value}
                  onChange={e => {
                    onChange()
                    handlePMChange(e)
                    setValue('PaymentMethodID', e?.target?.value)
                  }}
                  defaultValue={editData ? editData?.PaymentMethodID : ''}
                  disabled={formMode === 'approve-reject'}
                  helperText={errors?.PaymentMethodID?.message}
                  error={errors?.PaymentMethodID ? true : false}
                >
                  {getPaymentMethod
                    ?.sort((a, b) => {
                      return a.Name.localeCompare(b.Name)
                    })
                    .map((el, index) => (
                      <MenuItem key={index} value={el.PaymentMethodID}>
                        {el.Name}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              control={control}
              helperText={errors?.PaymentMethodID?.message}
              error={errors?.PaymentMethodID ? true : false}
              onChange={e => {
                //handlePMChange(e)
                setValue('PaymentMethodID', e?.target?.value)
              }}
              ref={register()}
              name="PaymentMethodID"
              autoComplete="off"
              value={editData?.PaymentMethodID}
              margin="dense"
              multiline={true}
              fullWidth
              required
            />

            {getPaymentMethod
              .find(el => el.PaymentMethodID === watch('PaymentMethodID'))
              ?.Name?.includes('Card') && (
              <Controller
                render={({ onChange, value }) => (
                  <TextField
                    select
                    required
                    name="CreditCardID"
                    label="Card Type"
                    onChange={e => {
                      onChange(e)
                      handleCCChange(e)
                      //setValue('CreditCardID', e?.target?.value)
                    }}
                    defaultValue={editData ? editData?.CreditCardID : ''}
                    disabled={mode == 'approve-reject'}
                    // helperText={errors?.CreditCardID?.message}
                    // error={errors?.CreditCardID ? true : false}
                  >
                    {getCreditCardType
                      ?.sort((a, b) => {
                        return a.Name.localeCompare(b.Name)
                      })
                      .map((el, index) => (
                        <MenuItem key={index} value={el.CreditCardID}>
                          {`${el.Name}`}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
                label="Card Type"
                name="CreditCardID"
                autoComplete="off"
                control={control}
                // helperText={errors?.CreditCardID?.message}
                // error={errors?.CreditCardID ? true : false}
                multiline={true}
                // defaultValue={getPayment[0]?.CreditCardID}
                fullWidth
                margin="dense"
                ref={register}
              />
            )}

            {getPaymentMethod.find(
              el => el.PaymentMethodID === watch('PaymentMethodID')
            )?.Name === 'Cheque' && (
              <>
                <Controller
                  as={TextField}
                  id="standard-basic"
                  name="ChequeNo"
                  label="Cheque No"
                  className="left"
                  autoComplete="off"
                  control={control}
                  margin="dense"
                  required
                  ref={register}
                  // helperText={errors?.ChequeNo?.message}
                  // error={errors?.ChequeNo ? true : false}
                  defaultValue={editData ? editData?.ChequeNo : ''}
                  disabled={mode == 'approve-reject'}
                />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    required
                    as={KeyboardDatePicker}
                    name="ChequeDate"
                    label="Cheque Date"
                    control={control}
                    onChange={(date: Date | null) => {}}
                    format="dd/MM/yyyy"
                    value={
                      // formMode === 'add' ? new Date() : editData?.ChequeDate
                      watch('ChequeDate')
                    }
                    margin="dense"
                    allowKeyboardControl
                    //inputRef={register({})}
                    ref={register}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    defaultValue={
                      formMode === 'add' ? new Date() : editData?.ChequeDate
                    }
                    showTodayButton
                    className="right"
                    disabled={mode == 'approve-reject'}
                    // helperText={errors?.ChequeDate?.message}
                    // error={errors?.ChequeDate ? true : false}
                  />
                </MuiPickersUtilsProvider>
              </>
            )}

            {/* this issue fixes due to issue of AX-20 where error message appeared when user keyed in 4 digits Amount */}

            {/* <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            id="standard-basic"
            name="Amount"
            label="Amount"
            className="left"
            value={cost}
            autoComplete="off"
            control={control}
            onValueChange={e => {
              handleAmtChange(e)
            }}
            decimalScale={2}
            fixedDecimalScale
            margin="dense"
            required
            helperText={errors?.Amount?.message}
            error={errors?.Amount ? true : false}
            ref={register}
            defaultValue={editData ? editData?.Amount : 0}
            disabled={formMode === 'approve-reject' ? true : false}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconText
                    icon={
                      <img
                        src={BlackDollarIcon}
                        style={{ width: '17px', marginBottom: '3px' }}
                      />
                    }
                  />
                </InputAdornment>
              ),
            }}
          /> */}

            <Controller
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  customInput={TextField}
                  allowNegative={false}
                  variant="standard"
                  margin="dense"
                  decimalScale={2}
                  fixedDecimalScale
                  label="Amount"
                  thousandSeparator
                  required
                  helperText={errors?.Amount?.message}
                  error={errors?.Amount ? true : false}
                  defaultValue={value}
                  disabled={formMode === 'approve-reject' ? true : false}
                  onValueChange={({ floatValue, value }) => {
                    onChange(floatValue)
                    handleAmtChange(floatValue)
                  }}
                  fullWidth
                />
              )}
              name="Amount"
              fullWidth
              ref={register}
              control={control}
              defaultValue={editData ? editData?.Amount : 0}
            />

            <Grid
              item
              xs={6}
              style={{
                marginBottom: '5px',
                paddingRight: '10px',
              }}
            >
              <Controller
                as={
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Tax"
                    required
                    SelectProps={{
                      renderValue: () => `${selectedTax?.Code}`,
                      onChange: event => {
                        handleTaxChange(event, taxEffectiveDate)
                        // handleTaxRateChange(event)
                        setValue('TaxSchemeID', event?.target?.value.toString())
                        clearErrors('TaxSchemeID')
                      },
                    }}
                    select
                  >
                    {/* <ListSubheader>Input</ListSubheader>
                  {taxSchemeInput?.map((tax, index) => (
                    <MenuItem
                      id="tax-select"
                      key={index}
                      value={tax?.TaxSchemeID}
                    >
                      <span className="text-noflow">
                        {`${tax?.Code} `} {`(${tax?.LatestTax?.Rate}%)`}
                      </span>
                    </MenuItem>
                  ))}
                  <ListSubheader>Output</ListSubheader> */}
                    {taxSchemeOutput?.map((tax, index) => (
                      <MenuItem
                        id="tax-select"
                        key={index}
                        value={tax?.TaxSchemeID}
                      >
                        <span className="text-noflow">{`${tax?.Code}`}</span>
                      </MenuItem>
                    ))}
                  </TextField>
                }
                select
                name="TaxSchemeID"
                autoComplete="off"
                control={control}
                fullWidth
                ref={register}
                helperText={errors?.TaxSchemeID?.message}
                error={errors?.TaxSchemeID ? true : false}
                disabled={formMode === 'approve-reject' ? true : false}
              />
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                marginBottom: '5px',
              }}
            >
              <TextField
                name="TaxRate"
                label="Tax Rate %"
                value={amtStr(
                  getTaxScheme?.filter(
                    bank => bank?.TaxSchemeID === selectedTax?.TaxSchemeID
                  )[0]?.LatestTax?.Rate
                )}
                disabled
                // className="right"
                margin="normal"
                // ref={register}
                style={{ marginTop: '5px' }}
              />
            </Grid>

            <Controller
              as={<NumberFormat allowNegative={false} />}
              thousandSeparator
              customInput={TextField}
              ref={register}
              control={control}
              margin="dense"
              name="TaxAmt"
              label="Tax Amount"
              disabled={formMode === 'approve-reject' ? true : false}
              onValueChange={e => {
                handleTaxAmtChange(e)
              }}
              decimalScale={2}
              fixedDecimalScale
              className="left"
              required
            />

            <TextField
              name="DocAmt"
              // variant="outlined"
              label="Total Amount"
              value={amtStr(getTotalAmt(true, cost, taxAmt, TaxRate))}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ marginRight: '5px' }}
                  ></InputAdornment>
                ),
              }}
              className="outlined-disabled right"
              aria-disabled
              margin="dense"
              ref={register}
              style={{ color: 'white' }}
              disabled
            />

            <Controller
              as={TextField}
              fullWidth
              helperText={errors?.TaxInvoiceNo?.message}
              error={errors?.TaxInvoiceNo ? true : false}
              autoComplete="off"
              margin="dense"
              name="TaxInvoiceNo"
              label="Tax Invoice No."
              control={control}
              ref={register}
            />

            <DropdownTextfield
              // mount={bankNameNeeded}
              disabled={formMode === 'approve-reject' ? true : false}
              helperText={errors?.BankAccountID?.message}
              error={errors?.BankAccountID ? true : false}
              label="Bank Account No"
              handleChangeEnabled
              handleChange={handleBankChange}
              selectProps={{
                renderValue: () =>
                  `${selectedBank?.AccountNo} (${selectedBank?.BankProfile?.Name})`,
              }}
              customDropdown={getBankAccount?.map((el, index) => (
                <MenuItem
                  id="contract-select"
                  key={index}
                  value={el?.BankAccountID}
                >
                  <div className="content-wrap full">
                    <div>
                      <span className="xsTitle">
                        {el.BankProfile?.Name} ({el.BankProfile?.Address?.city})
                      </span>
                    </div>
                    <div className="desc">
                      Account No: {`${el?.AccountNo} (${el?.Code})`}
                    </div>
                  </div>
                </MenuItem>
              ))}
              name="BankAccountID"
              control={control}
              register={register}
              // defaultValue={watch(`BankAccountID`)}
            />

            <Controller
              as={TextField}
              id="standard-basic"
              name="Description"
              label="Description"
              required
              autoComplete="off"
              control={control}
              fullWidth
              margin="dense"
              helperText={errors?.Description?.message}
              error={errors?.Description ? true : false}
              ref={register}
              defaultValue={
                formMode === 'edit' || mode === 'approve-reject'
                  ? editData?.Description
                  : ''
              }
              disabled={mode === 'approve-reject'}
              style={{
                //color: '#96938e',
                //fontSize: '14px',
                backgroundColor: 'white',
                // marginBottom: '40px',
              }}
            />

            <VoiceTextField
              mounted={true}
              label="Remark"
              name="Remark"
              value={voiceRemark}
              setValue={setValue}
              record={record}
              setRecord={setRecord}
              control={control}
              controllerProps={{
                error: errors?.Remark ? true : false,
                helperText: errors?.Remark?.message,
                ref: register,
                autoComplete: 'off',
              }}
            />

            <DocUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                'application/msword, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              mapData={'application'}
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        // files[i]?.type
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
              disabled={mode === 'approve-reject'}
            />
          </CardContents>
        </ContentWrapper>
      )}

      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        sections={{
          header: {
            title: 'Exit Confirmation',
          },
          body: () => (
            <>
              <div>
                Are you sure you want to exit? Your changes will not be saved.
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => history.goBack(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={errorDia}
        onClose={() => setErrorDia(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        {'Document Numbering is empty'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>{'Please setup Document Numbering to Submit'}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDia(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-receivable/${CompanyID}/advance`)
        }}
        // hasInfo={hasInfo}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
